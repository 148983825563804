import React from 'react';
import './WorkforceIdentity.css';

import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import key_img from "./points.jpg";
import security from "./productivity.png";
import productivity from "./productivity2.png";
import verified from "./security2.png";

import banner from './workforce2.jpg';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card, ListGroup } from 'react-bootstrap'; 
// import { FaShieldAlt, FaUserCog , FaRegLightbulb,FaClipboardCheck,FaRocket,FaLock, FaCloudUploadAlt,FaTachometerAlt, FaLayerGroup, FaPlug, FaChartLine } from 'react-icons/fa';
import {useState , useEffect } from 'react';
import * as FaIcons from "react-icons/fa";
import assesment from "./j1.jpg"
import img1 from './img1.jpg'
import img2 from './img2.jpg'
import img3 from './img3.jpg'

import banner1 from './service21.jpg'
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos'; // Import AOS
import DOMPurify from 'dompurify';
import { Helmet } from 'react-helmet';
import Loader from "../../components/loader/loader";


const WorkforceIdentity = () => {



  const [itemsToDisplay, setItemsToDisplay] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}api/identify-offerings`);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const result = await response.json();
        console.log("API Response:", result); // Log to verify structure
        setData(result);
      } catch (err) {
        console.error("API Fetch Error:", err.message);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    AOS.init({ duration: 1000, once: true });
  }, []);

  useEffect(() => {
    if (data) {
      // Dynamically create items based on the available data keys
      const items = Object.keys(data?.workforce_identity_access_management || {})
        .filter((key) => key.startsWith("title_")) // Filter out keys starting with 'title_'
        .map((key, index) => {
          const i = index + 1; // Assuming your titles start from title_1, content_1, image_1
          const title = data?.workforce_identity_access_management?.[`title_${i}`];
          const text = data?.workforce_identity_access_management?.[`content_${i}`];
          const image = data?.workforce_identity_access_management?.[`image_${i}`];

          if (!title || !text || !image) {
            console.warn(`Missing data for item ${i}:`, { title, text, image });
          }

          return {
            title: title || `Default Title ${i}`,
            text: text || `Default Content ${i}`,
            image: image || "/default-image.jpg",
          };
        });
        console.log(data?.workforce_identity_access_management?.logo);

      console.log("Mapped Items After Fallback:", items);
      setItemsToDisplay(items);
    }
  }, [data]);

  // if (loading) return <div className="loading">Loading...</div>;
  // if (error) return <div className="error">Error: {error}</div>;

  return (
    <>
    {loading ? (
      <Loader />
   ) : (
    
    <>
       <Helmet>
        <title> {data?.workforce_identity_access_management?.meta_title || "Workforce Identity and Access Management " } </title>
        <meta name="description" content= {data?.workforce_identity_access_management?.meta_description || "Workforce Identity and Access Management "  } />
        <meta
        name="keywords"
        content= {data?.workforce_identity_access_management?.meta_keywords || "Workforce Identity and Access Management " } 
      />
      </Helmet>

      <Header identityPage={true} />

       {/* Image and Overlay Section */}
       <div className="identity-image-container">
        <img
          src={banner1}
          alt="A depiction of the company's philosophy"
          className="identity-page-image"
        />
        <div className="overlay-text">
          <h1>Workforce Identity and Access Management </h1>
          <p>Home <i className="fa-solid fa-greater-than benner-icon"></i> Servics<i className="fa-solid fa-greater-than benner-icon"></i> Identify Offerings<i className="fa-solid fa-greater-than benner-icon"></i> (WIAM)</p>
        </div>
      </div>


      
      <div className="workforce-identity-section" data-aos="fade-up">
        <p className="workforce-identity-description">
        {data?.workforce_identity_access_management?.content || " At Securoak, our Workforce Identity and Access Management (WIAM) solutions harness the power of AI to enable organizations to provide employees with secure access to essential resources exactly when needed. Picture it as granting access to a high-security vault - only those with the correct credentials can enter, effectively safeguarding sensitive information while enhancing productivity. By integrating AI-driven insights with robust cybersecurity measures, we streamline access management and fortify security across your workforce." }
         
        </p>
      </div>





      <h4 className="feature-heading">
      {data?.workforce_identity_access_management?.key_feature_main_title || " Key Differentiators of (WIAM)" }
   
     </h4>

<Row className="align-items-center Features" data-aos="fade-up">
  {/* Feature Cards on the Left */}
  <Col md={12}>
    <div className="keyfeatures-container container-fluid">
      <div className="features-list">
        <Row className="identify-cards">
          {data?.workforce_identity_access_management?.key_feature_title.map((title, index) => {
            // Extract the icon name from the logo URL
            const iconName = data.workforce_identity_access_management.icons[index]
              .split("/")
              .pop(); // Get the last part of the URL

            const IconComponent = FaIcons[iconName]; // Map to the corresponding icon

            return (
              <Col md={12} className="feature-point" key={index}>
                <div className="feature-icon">
                  {/* Render the corresponding logo/icon */}
                 
                </div>
                <div className="feature-text">
                {IconComponent && <IconComponent size={25} className="feature-icon pr-2" /> ||  <IconComponent size={25} className="feature-icon pr-2" /> }
                <strong>{title || "Scalability and Security:"}</strong>
                  <br />
                  {data.workforce_identity_access_management.key_feature_content[index] || "While Customer Identity and Access Management (CIAM) solutions scale for a vast number of external users, our WIAM offerings prioritize controlled, high-security access for internal teams. Our cybersecurity framework integrates seamlessly with AI capabilities to proactively identify and address vulnerabilities, effectively protecting valuable company data from unauthorized access."}
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  </Col>
</Row>;


<section className="journey-with-securoak" data-aos="fade-up">
      <h2 className="text-center journey-title">
        {data?.workforce_identity_access_management?.journey_title || "Journey With Securoak"}
      </h2>
      <p className="title-below-para">
        {data?.workforce_identity_access_management?.journey_content || "Partnering with Securoak for your WIAM journey means embracing a structured approach to identity management that unlocks your organization’s full potential."}
      </p>
      <div className="container p-4">
        {itemsToDisplay.length > 0 ? (
          itemsToDisplay.map((item, index) => (
            <div
              key={index}
              className={`card-main d-flex mb-1 ${index % 2 === 0 ? "flex-row-reverse" : "flex-row"}`}
            >
              <div className="content flex-grow-1 d-flex flex-column align-items-center justify-content-start pt-2">
                <h4>{item.title || "Implementation"}</h4>
                <div className="list-content">
                  <span>{item.text || "We facilitate a smooth implementation of our solutions, ensuring minimal disruption to your operations while enhancing security protocols. Our deployment process incorporates automated monitoring to detect and respond to threats in real-time."}</span>
                </div>
              </div>
              <div className="card-image d-flex flex-column align-items-center justify-content-start">
                <img
                  src={item.image ? item.image : assesment}
                  className="unlock-img"
                  alt={item.title}
                  onError={(e) => (e.target.src = "./j1.jpg")}
                />
              </div>
            </div>
          ))
        ) : (
          <div>No items to display</div>
        )}
      </div>
    </section>

    {/* <section className="journey-with-securoak">
    <div className='text-center'>
  <h2 className="c journey-title">Journey With Securoak</h2>
  <p className='title-below-para'>Partnering with Securoak for your WIAM journey means embracing a structured approach to identity management that unlocks your organization’s full potential.</p>
</div>
      <Card className="mb-4 journey-card">
        <Card.Body className="d-flex flex-row">
          <div className="content-column">
            <ListGroup variant="flush">
              {potentialItems.map((item, index) => (
                <ListGroup.Item key={index} className="list-item">
                  <div className="list-icon">{item.icon}</div>
                  <span>{item.text}</span>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>

          <div className="image-column">
          <dotlottie-player 
    src="https://lottie.host/b6f681e0-7777-4f16-83f5-b6f2bb35c65a/ExvDWUEra1.json"
    background="transparent"
    speed="1"
    className="potential-lottie"
    loop
    autoplay
  ></dotlottie-player>
          </div>
        </Card.Body>
      </Card>
    </section> */}

     
    {/* Unlocking Your Potential section */}

    <section className="unlocking-potential-section">
  <div className="container">
    <h2 className="section-title">{data?.workforce_identity_access_management?.unlock_your_potential_title || "Unlocking Your Potential"}</h2>
    <p className="section-description">
      {data?.workforce_identity_access_management?.your_journey_main_content || 
        "By choosing Securoak's WIAM solutions, you unlock a myriad of business benefits:"}
    </p>
    <div className="benefits-list">
      {data?.workforce_identity_access_management?.your_journey_title?.map((title, index) => (
        <div 
          className="benefit-item" 
          data-aos="fade-up" 
          data-aos-delay={200 + index * 100} 
          key={index}
        >
          <div className="benefit-content">
            <img 
              src={process.env.REACT_APP_API + 'uploads/services/workforce-identity-and-access-management/' + data?.workforce_identity_access_management?.logo?.[index] || "/default-image.png"} 
            
              alt={title} 
              className="benefit-image" 
            />
            <div className="benefit-text">
              <strong>{title}</strong><br />
              {data?.workforce_identity_access_management?.your_journey_content?.[index] || "Default content."}
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
</section>




<section className="why-partner-with-securoak why-partner-with-securoak5" data-aos="fade-up">
  <h2 className="partner-with-securoak-title">
  {data?.workforce_identity_access_management?.why_partner_title || " Why Partner with Securoak?."} 
   
    </h2>
  <p className="section-description">
  <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              data?.workforce_identity_access_management?.why_partner_content || ""
            ),
          }}
        />
  </p>
  {/* <p className="section-description">
    With our proven track record and unwavering commitment to innovation, we empower your organization to navigate the complexities of modern security challenges with confidence, ensuring a resilient and productive workforce ready for the demands of today’s digital landscape.
  </p> */}
</section>





      <Footer />
    </>
  )
};
</>
);
}
export default WorkforceIdentity;
