import React from 'react'
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import banner from './procure.jpg';
import "./It-Procurement.css"
import security from "../WorkforceIdentity/productivity.png";
import productivity from "../WorkforceIdentity/productivity2.png";
import verified from "../WorkforceIdentity/security2.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import partner from "../../pages/IdentityGovernance/partner.jpg";
import { Card, ListGroup } from 'react-bootstrap'; // Assuming you're using Bootstrap for card and list styling
import { FaShieldAlt, FaRegLightbulb,FaClipboardCheck,FaRocket,FaLock, FaCloudUploadAlt,FaTachometerAlt, FaLayerGroup, FaPlug, FaChartLine } from 'react-icons/fa';
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos'; // Import AOS
import  {useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import DOMPurify from 'dompurify';
import * as FaIcons from "react-icons/fa";
import encript from "./encript.png"
import Loader from "../../components/loader/loader";


const ItProcurement = () => {


  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [itemsToDisplay, setItemsToDisplay] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}api/infrastructure-managed-services/it-procurement`
        );
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const result = await response.json();
        setData(result[0]);
      } catch (err) {
       
        console.error("API Fetch Error:", err.message);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    
    fetchData();
    AOS.init({ duration: 1000, once: true });
  }, []);

  useEffect(() => {
    if (data?.workforce_identity_access_management) {
      const items = Object.keys(data)
        .filter((key) => key.startsWith("title_"))
        .map((key, index) => {
          const i = index + 1; // Map to corresponding keys
          const title = data[`title_${i}`];
          const text = data[`content_${i}`];
          const image = data[`image_${i}`];
  
          if (!title || !text || !image) {
            console.warn(`Missing data for item ${i}:`, { title, text, image });
          }
  
          return {
            title: title || `Default Title ${i}`,
            text: text || `Default Content ${i}`,
            image: image || "/default-image.jpg",
          };
        });
      
      console.log("Mapped Items After Fallback:", items);
      setItemsToDisplay(items); // Update state
    }
  }, [data]);

  const softwareLicensingCards = [
    {
      icon: data?.icon_1||"FaShieldAlt",
      title: data?.title_1 || "Licensing Management",
      content: data?.content_1 || "We navigate complex licensing requirements on your behalf, ensuring compliance while optimizing costs and reducing administrative burdens.",
    },
    {
      icon: data?.icon_2||"FaCogs",
      title: data?.title_2 || "Tailored Solutions",
      content: data?.content_2 || "Receive expert recommendations for the most suitable software licenses based on your organization’s specific needs and growth plans, including options that leverage AI for better performance.",
    },
    {
      icon: data?.icon_3||"FaSyncAlt",
      title: data?.title_3 || "Renewal Support",
      content: data?.content_3 || "We provide assistance with license renewals to avoid lapses, ensuring uninterrupted service and operational continuity.",
    },
  ];


  
  // Map each card with the correct icon
  const voipCards = [
    {
      icon: data?.logo_4 || "FaHeadset", 
      title: data?.title_4 || "Unified Communication Solutions",
      content:
        data?.content_4 ||
        "Implement flexible VOIP solutions that enhance communication and collaboration within your organization, driving productivity and engagement while ensuring secure data transmission.",
    },
    {
      icon: data?.logo_5 || "FaChartLine", 
      title: data?.title_5 || "Scalability",
      content:
        data?.content_5 ||
        "Our VOIP systems are designed to grow with your business, easily adapting to changing needs without compromising on performance.",
    },
    {
      icon: data?.logo_6 || "FaTools", 
      title: data?.title_6 || "Support & Maintenance",
      content:
        data?.content_6 ||
        "Comprehensive support ensures your VOIP systems operate effectively, minimizing downtime and enhancing overall productivity.",
    },
  ];
  

  const cybersecurity = [
    {
      icon:  data?.logo_7 ||"FaShieldVirus",
      title: data?.title_7 || "Cybersecurity Integration",
      content: data?.content_7 || "Implement flexible VOIP solutions that enhance communication and collaboration within your organization, driving productivity and engagement while ensuring secure data transmission.",
    },
    {
      icon:  data?.logo_8 ||"FaRobot",
      title: data?.title_8 || "Scalability",
      content: data?.content_8 || "Our VOIP systems are designed to grow with your business, easily adapting to changing needs without compromising on performance.",
    },
    {
      icon: data?.logo_9 || "FaBalanceScale",
      title: data?.title_9 || "Support & Maintenance",
      content: data?.content_9 || "Comprehensive support ensures your VOIP systems operate effectively, minimizing downtime and enhancing overall productivity.",
    },
  ];

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Helmet>
            <title>{data?.meta_title || "IT Procurement"}</title>
            <meta
              name="description"
              content={data?.meta_description || "IT Procurement"}
            />
            <meta
              name="keywords"
              content={data?.meta_keywords || "IT Procurement"}
            />
          </Helmet>
          <Header identityPage={true} />
          {/* Image and Overlay Section */}
          <div className="identity-image-container w-100">
            <img
              src={data?.banner_image || banner}
              alt="A depiction of the company's philosophy"
              className="identity-page-image"
            />
            <div className="overlay-text">
              <h1>IT Procurement </h1>
              <p>
                Home <i className="fa-solid fa-greater-than benner-icon"></i>{" "}
                Services{" "}
                <i className="fa-solid fa-greater-than benner-icon"></i>{" "}
                Infrastructure & Managed Services
                <i className="fa-solid fa-greater-than benner-icon"></i> IT
                Procurement
              </p>
            </div>
          </div>

          <div className="procurement w-100" data-aos="fade-up">
            {/* <h2 className="procurement-title">Comprehensive IT Procurement Services</h2> */}
            <p className="procurement-text">
              {data?.main_content ||
                " At Securoak, we provide comprehensive IT procurement services meticulously designed to equip your organization with the right hardware and software solutions tailored to your operational needs. Our offerings encompass everything from initial sales to ongoing support, ensuring a seamless and efficient experience throughout your IT procurement journey, all while prioritizing cybersecurity and leveraging artificial intelligence to enhance performance."}
            </p>
          </div>

          <h4 className="feature-heading">
            {data?.your_journey_main_title || "Your Journey with Us"}
          </h4>
          <p className="section-description">
            {data?.your_journey_main_content ||
              "Embarking on your procurement journey with Securoak is both straightforward and collaborative. Here’s how we work together to ensure your success:"}
          </p>

          <Row className="align-items-center Features" data-aos="fade-up">
            <Col md={12}>
              <div className="keyfeatures-container container-fluid">
                <div className="features-list">
                  <Row className="identify-cards">
                    {data?.your_journey_title.map((title, index) => {
                      const iconUrl = data?.icons[index];
                      const iconName = iconUrl
                        ?.split("/")
                        .pop()
                        ?.replace(/\.\w+$/, ""); // Remove file extension
                      const IconComponent = FaIcons[iconName]; // Map to corresponding FontAwesome icon

                      return (
                        <Col md={12} className="feature-point" key={index}>
                          <div className="feature-text">
                            {/* Render icon or fallback */}
                            {IconComponent ? (
                              <IconComponent
                                size={30}
                                className="feature-icon "
                              />
                            ) : (
                              <span className="fallback-icon">🚀</span> // Default fallback icon
                            )}

                            <strong>
                              {title || "Scalability and Security:"}
                            </strong>
                            <br />
                            {data.your_journey_content[index] ||
                              "While Customer Identity and Access Management (CIAM) solutions scale for a vast number of external users, our WIAM offerings prioritize controlled, high-security access for internal teams. Our cybersecurity framework integrates seamlessly with AI capabilities to proactively identify and address vulnerabilities, effectively protecting valuable company data from unauthorized access."}
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </div>
            </Col>
          </Row>

          <section className="unlocking-potential-section">
            <div className="container">
              <h2 className="section-title">
                {data?.key_offering_main_title || "Key Offerings"}
              </h2>
              <p className="section-description">
                {data?.key_offering_main_content ||
                  "Hardware & Software Sales & Support"}
              </p>
              <div className="benefits-list">
                {data?.key_offerings_title?.map((title, index) => (
                  <div
                    className="benefit-item"
                    data-aos="fade-up"
                    data-aos-delay={200 + index * 100}
                    key={index}
                  >
                    <div className="benefit-content">
                      <img
                        src={data?.logo?.[index] || encript}
                        alt={title}
                        className="benefit-image"
                      />
                      <div className="benefit-text">
                        <strong>{title || "End-to-End Procurement"}</strong>
                        <br />
                        {data?.key_offerings_content?.[index] ||
                          "We assist in sourcing, purchasing, and deploying the right hardware and software tailored to your organization, streamlining the entire process while embedding AI capabilities for enhanced functionality."}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>

          <section className="software-voip-solutions" data-aos="fade-up">
            <div className="container">
              <h2 className="section-title">
                {data?.software_licensing_main_title ||
                  "Software Licensing & VOIP Solutions"}
              </h2>
              {/* Software Licensing Section */}
              <div className="software-licensing">
                <h3 className="sub-title">
                  {data?.software_licensing_title || "Software Licensing"}
                </h3>

                <div className="licensing-cards">
                  {softwareLicensingCards.map((card, index) => {
                    // Accessing the correct icon from the FaIcons mapping
                    const IconComponent = FaIcons[card.icon];
                    return (
                      <div className="licensing-card" key={index}>
                        <div className="card-icon">
                          {IconComponent ? (
                            <IconComponent
                              size={50}
                              className="feature-icon pr-2"
                            />
                          ) : (
                            <i className="fas fa-question-circle"></i>
                          )}
                        </div>
                        <h4>
                          {card.title ? card.title : "Licensing Management"}
                        </h4>
                        <p>
                          {card.content
                            ? card.content
                            : "We navigate complex licensing requirements on your behalf, ensuring compliance while optimizing costs and reducing administrative burdens."}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
              ;{/* VOIP Solutions Section */}
              <div className="voip-solutions" data-aos="fade-up">
                <h3 className="sub-title">
                  {data?.on_premise_cloud_voip_title ||
                    "On-Premise/Cloud VOIP Solutions"}
                </h3>
                <div className="voip-cards">
                  {voipCards.map((card, index) => {
                    const IconComponent = FaIcons[card.icon];
                    // Dynamically import the correct icon
                    // const IconComponent = iconMapping[card.icon];
                    return (
                      <div className="voip-card" key={index}>
                        <div className="card-icon">
                          {IconComponent ? (
                            <IconComponent
                              size={50}
                              className="feature-icon pr-2"
                            />
                          ) : (
                            <i className="fas fa-question-circle"></i>
                          )}
                        </div>
                        <h4>
                          {card.title ? card.title : "Support & Maintenance"}
                        </h4>
                        <p>
                          {card.content
                            ? card.content
                            : "Comprehensive support ensures your VOIP systems operate effectively, minimizing downtime and enhancing overall productivity."}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
              ;
            </div>
          </section>

          <section className="cybersecurity-integration" data-aos="fade-up">
            <div className="cybersecurity-container">
              <h2 className="section-title">
                {data?.cybersecurity_integration_title ||
                  "Cybersecurity Integration"}
              </h2>

              <div className="cybersecurity-features">
                {cybersecurity.map((card, index) => {
                  const IconComponent = FaIcons[card.icon];
                  return (
                    <div className="cybersecurity-feature" key={index}>
                      <div className="feature-icon">
                        {IconComponent ? (
                          <IconComponent
                            size={50}
                            className="feature-icon pr-2"
                          />
                        ) : (
                          <i className="fas fa-question-circle"></i>
                        )}
                      </div>
                      <h4 className="cyber-head">
                        {card.title
                          ? card.title
                          : "AI-Powered Security Solutions"}
                      </h4>
                      <p>
                        {card.content
                          ? card.content
                          : "We offer advanced cybersecurity solutions powered by artificial intelligence, providing real-time threat detection and response capabilities to safeguard your organization against evolving risks."}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>

          <section className="why-partner-section" data-aos="fade-up">
            <div className="why-partner-top">
              <h2 className="why-partner-title">
                {data?.why_partner_title || "Why Partner with Securoak?"}
              </h2>
              <p className="why-partner-description  why-partner-description3 description4">
                {data?.why_partner_main_content ||
                  " With Securoak’s IT procurement services, you can equip your organization with the tools necessary for success in today’s dynamic environment. By choosing us as your trusted partner, you unlock your potential to drive innovation, efficiency, and sustainable growth, empowering your business to thrive in an increasingly competitive landscape while effectively managing risks through advanced cybersecurity and AI-driven solutions. Partnering with Securoak for your IT procurement needs means gaining access to:"}
              </p>
            </div>

            <Row className="why-partner-content mb-5">
              <Col
                md={12}
                sm={12}
                lg={6}
                className="why-partner-image why-partner-image3"
              >
                <img
                  src={data?.why_partner_image || partner}
                  alt="Securoak"
                  className="image "
                  style={{ marginTop: "-15px", marginBottom: "30px" }}
                />
              </Col>

              <Col md={12} sm={12} lg={6} className="why-partner-features">
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(data?.why_partner_content || ""),
                  }}
                />
              </Col>
            </Row>
          </section>

          <Footer />
        </>
      )}
    </>
  );
}

export default ItProcurement;
