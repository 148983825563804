import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../assests/logo.png";
import "./header.scss";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLessThan } from "@fortawesome/free-solid-svg-icons";
import Loader from "../loader/loader";

function Header({ aboutPage, identityPage }) {
  const [scrolled, setScrolled] = useState(false);
  const [activeItem, setActiveItem] = useState("HOME");
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();

  const [itemsToDisplay, setItemsToDisplay] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}api/header`);
        if (!response.ok)
          throw new Error(`HTTP error! Status: ${response.status}`);
        const result = await response.json();
        console.log("API Response:", result); // Log to verify structure
        setData(result);
      } catch (err) {
        console.error("API Fetch Error:", err.message);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleScroll = () => {
    setScrolled(window.scrollY > 50);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const setActiveItemBasedOnPath = () => {
      const { pathname } = location;

      // Mapping paths to active items
      const pathToItemMap = {
        "/": "HOME",
        "/about": "ABOUT US",
      };

      // Services paths grouped
      const servicePaths = [
        "/digitalidentity-transformation",
        "/identity-access-management",
        "/Workforce-Identity",
        "/identity-governance",
        "/Privileged-access-management",
      ];

      // Determine the active item
      if (pathToItemMap[pathname]) {
        setActiveItem(pathToItemMap[pathname]);
      } else if (servicePaths.some((path) => pathname.startsWith(path))) {
        setActiveItem("OUR SERVICES");
      }
    };

    setActiveItemBasedOnPath();
  }, [location.pathname]);

  const Data = [
    { Title: "Home", Link: "/" },
    // { Title: "About Us", Link: "/about" },
    {
      Title: "Our Services",
      Link: "#",
      Submenu: [
        {
          Title: <>Identity Offerings</>,
          Link: "",
          Submenu: [
            {
              Title: "Digital Identity Transformation",
              Link: "/digitalidentity-transformation",
            },
            {
              Title: "Customer Identity and Access Management",
              Link: "/identity-access-management",
            },
            {
              Title: "Workforce Identity and Access Management",
              Link: "/workforce-identity",
            },
            { Title: "Identity Governance", Link: "/identity-governance" },
            {
              Title: "Privilege Access Management",
              Link: "/Privileged-access-management",
            },
            // { Title: "Managed Support Services", Link: "/Managedsupportservices" }
          ],
        },
        {
          Title: "Application Integration Services",
          Link: "/application-integration-services",
        },
        {
          Title: "Infrastructure & Managed Services",
          // Link: "/",
          Submenu: [
            { Title: "IT Consulting", Link: "/it-consulting" },
            { Title: "NOC & SOC ", Link: "/Noc-soc" },
            {
              Title: "Network and Telephony Solutions",
              Link: "/network-telephony-solutions",
            },
            { Title: "Managed IT Services", Link: "/managed-it-services" },
            { Title: "IT Procurement", Link: "/it-procurement" },
          ],
        },
        {
          Title: "Staffing and Outsourcing Services",
          // Link: "/",

          Submenu: [
            { Title: "Staffing Services ", Link: "/staffing-services " },
            { Title: "Outsourcing Services", Link: "/outsourcing-services" },
          ],
        },
      ],
    },
    { Title: "Events", Link: "/upcoming-events" },
    { Title: "Contact Us", Link: "/contact-us" },
  ];

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className={`header_navebar ${scrolled ? "scrolled" : ""} ${
        aboutPage ? "header-about" : ""
      } ${identityPage ? "header-identity" : ""}`}
    >
      <Container>
        <Navbar.Brand as={Link} to="/" className="icon-logo">
          <div className="main_logo">
            <img
              src={
                data?.header_footer?.logo
                  ? `${process.env.REACT_APP_API}uploads/logo/${data.header_footer.logo}`
                  : logo
              }
              alt="Company Logo"
              title="Company Logo"
              loading="lazy"
            />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="custom-toggle"
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="header_title">
            {Data.map((value, index) => (
              <div
                key={index}
                className={`nav-item header-nav-item ${
                  activeItem === value.Title ? "active" : ""
                }`}
                onMouseEnter={() =>
                  value.Title === "Our Services" && setShowDropdown(true)
                }
                onMouseLeave={() =>
                  value.Title === "Our Services" && setShowDropdown(false)
                }
              >
                <Link
                  to={value.Link}
                  className={`nav-link ${
                    location.pathname === value.Link ? "active" : ""
                  }`}
                  onClick={() => setActiveItem(value.Title)}
                >
                  {value.Title}
                </Link>

                {value.Submenu && showDropdown && (
                  <div
                    className="dropdown-menu"
                    aria-haspopup="true"
                    aria-expanded="true"
                  >
                    {value.Submenu.map((subItem, subIndex) => (
                      <div key={subIndex} className="dropdown-subitem">
                        <Link
                          to={subItem.Link || "#"}
                          className={`dropdown-item ${
                            location.pathname === subItem.Link ? "active" : ""
                          }`}
                          onClick={() =>
                            subItem.Link && setActiveItem("Our Services")
                          }
                        >
                          {subItem.Title}
                        </Link>

                        {subItem.Submenu && (
                          <div className="dropdown-menu-inner">
                            {subItem.Submenu.map(
                              (innerSubItem, innerSubIndex) => (
                                <Link
                                  to={innerSubItem.Link}
                                  key={innerSubIndex}
                                  className={`dropdown-item-inner ${
                                    location.pathname === innerSubItem.Link
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() => setActiveItem("Our Services")}
                                >
                                  {innerSubItem.Title}
                                </Link>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
