import React, { useEffect } from 'react';
import './WhoWeAre.scss'; // SCSS styling for the component
import largeImage from './whoweare2.jpg'; // Import the image file
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos'; // Import AOS
import { Container, Row, Col } from 'react-bootstrap';
import DOMPurify from 'dompurify';

const WhoWeAre = ({ data }) => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with animation duration
  }, []);

  // Check if 'data' and 'Home_about' are defined, then get the image or fallback to largeImage
  const imageUrl = data?.Home_about?.image
    ? `${process.env.REACT_APP_API}${data.Home_about.image}`
    : largeImage; // Use fallback image if no image is provided

  // Use safe fallback for title and content in case of null/undefined values
  const title = data?.Home_about?.title || "Who We Are";
  const content = data?.Home_about?.content || "Securoak leads in advanced digital security, blending AI and expert-driven solutions to tackle complex cybersecurity and infrastructure challenges. Our services in Identity and Access Management, application integration, and managed IT drive performance, resilience, and compliance - enabling organizations to innovate securely, with tailored API development and end-to-end support through our Network and Security Operations Centers, Securoak enhances agility, mitigates risk, and empowers your business to thrive in today’s digital landscape.";

  return (
    <section className="who-we-are">
      <Container className="content-wrapper">
        <Row className="align-items-center">
          {/* Text Content */}
          <Col md={12} sm={12} data-aos="fade-right" className="text-content">
            <h1>{title}</h1>
            <p
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(content),
              }}
            />
          </Col>
          {/* Image Container */}
          <Col md={12} sm={12} data-aos="fade-up" className="image-container">
            <img src={imageUrl} alt="Who We Are" className="img-fluid" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default WhoWeAre;
