import React, { useState } from 'react';
import "./Outsourcing-services.css";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import banner from '../../components/assests/Outsourcing/outsource.jpg';
import Assesment from "../../pages/IdentityGovernance/assesment.png";
import Strategy  from "../../pages/IdentityGovernance/stratagy.png";
import Implementation from "../../pages/IdentityGovernance/implimentation.png";
import Training from "../../pages/IdentityGovernance/training.png";
import partner from "../../pages/IdentityGovernance/partner.jpg";
import Support from "../../pages/IdentityGovernance/customer-service.png";
import bpo from "../../components/assests/Outsourcing/outsource.jpg";
import costeffective from "../../components/assests/Outsourcing/costeffective2.jpg";
import globel_talent from "../../components/assests/Outsourcing/globel-talent2.jpg";
import innovation from "../../components/assests/Outsourcing/innovation2.jpg";
import itsource from "../../components/assests/Outsourcing/itsource2.jpg";
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos'; // Import AOS
import  { useEffect } from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Helmet } from 'react-helmet';
import Loader from "../../components/loader/loader";

const Outsourcingservices = () => {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [currentOffering, setCurrentOffering] = useState(0);
  const [offerings, setOfferings] = useState([]); // initialize as empty array

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}api/staffing-and-outsourcing`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        setData(result);
        const titles = result.outsourcing_services?.service_title || [];
        const descriptions = result.outsourcing_services?.service_content || [];
        const images = result.outsourcing_services?.service_image || [];
  
        const offerings = titles.map((title, index) => ({
          title: title || "No title available",
          description: descriptions[index] || "No description available.",
          image: images[index] || "default-image-url",
        }));
  
        setOfferings(offerings);
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setError(error.message); // Optionally set an error state
      } finally {
        setLoading(false); // Set loading to false once the data is fetched
      }
    };
  
    fetchData();
  }, []);
  

  useEffect(() => {
    AOS.init({ duration: 1000, once: true }); // Initialize AOS
  }, []);
  // State to manage the current offering
  

  // Offering titles and descriptions
  // const offerings = [
  //   {
  //     title: "Comprehensive Business Process Outsourcing (BPO)",
  //     description: "We provide tailored BPO solutions that enable you to outsource non-core functions such as customer service, finance, human resources, and IT support. This allows your team to concentrate on strategic initiatives that drive growth while we manage routine tasks securely and efficiently.",
  //     image: bpo
  //   },
  //   {
  //     title: "Flexible IT Outsourcing",
  //     description: "Our IT outsourcing services encompass a range of functions, including application development, maintenance, and infrastructure management. Leveraging AI and machine learning, we enhance your technology capabilities while proactively managing cybersecurity risks, freeing you from the complexities of in-house resource management.",
  //     image: itsource
  //   },
  //   {
  //     title: "Access to Global Talent",
  //     description: "With our extensive network, you gain access to a diverse pool of skilled professionals, including cybersecurity experts and AI specialists. We connect you with talent that possesses the necessary expertise to meet your specific project requirements, ensuring your operations are supported by the best in the industry.",
  //     image: globel_talent
  //   },
  //   {
  //     title: "Cost-Effective Solutions",
  //     description: "By outsourcing with Securoak, you can significantly reduce operational costs. Our solutions are designed to maximize value while minimizing overhead, enabling you to allocate resources more efficiently, especially towards innovation and security initiatives.",
  //     image: costeffective
  //   },
  //   {
  //     title: "Focus on Innovation",
  //     description: "Outsourcing with us allows your team to focus on innovation and strategic initiatives. With less time spent on routine tasks, your organization can drive creativity and growth, fostering a competitive edge in the market. Our AI-driven insights further enhance decision-making processes, enabling proactive responses to emerging trends.",
  //     image: innovation
  //   }
  // ];

  // Function to handle offering change
  const changeOffering = (direction) => {
    if (direction === 'next') {
      setCurrentOffering((prevOffering) => (prevOffering + 1) % offerings.length); // Loop to the start
    } else if (direction === 'prev') {
      setCurrentOffering((prevOffering) => (prevOffering - 1 + offerings.length) % offerings.length); // Loop to the end
    }
  };
  console.log(data);
  return (
    <>
    {loading ? (
      <Loader />
   ) : (
    <>
    <Helmet>
        <title> {data?.outsourcing_services?.meta_title || "outsourcing_services" } </title>
        <meta name="description" content= {data?.outsourcing_services?.meta_description || "outsourcing_services"  } />
        <meta
        name="keywords"
        content= {data?.outsourcing_services?.meta_keywords || "outsourcing_services " } 
      />
      </Helmet>
      <Header identityPage={true} />

      {/* Image and Overlay Section */}
      <div className="identity-image-container">
        <img
          src= {data?.outsourcing_services?.banner_image || banner}
          alt="A depiction of the company's philosophy"
          className="identity-page-image"
        />
        <div className="overlay-text">
          <h1>Outsourcing services</h1>
          <p>Home <i className="fa-solid fa-greater-than benner-icon"></i> Services <i className="fa-solid fa-greater-than benner-icon"></i> Staffing and Outsourcing Services <i className="fa-solid fa-greater-than benner-icon"></i> Outsourcing services</p>
        </div>
      </div>

      {/* Staffing and Outsourcing Description */}
      <section className="staffing-outsourcing">
        <div className="container">
          <div className="service-description"data-aos="fade-up">
            <p> {data?.soutsourcing_services?.content || "At Securoak, we understand that in today’s fast-paced business environment, focusing on core competencies while optimizing operational efficiency is crucial. Our Outsourcing Services are designed to help you streamline processes, reduce costs, and enhance productivity, allowing your organization to thrive in a digitally driven world. As cybersecurity threats evolve, integrating robust security measures into our outsourcing solutions ensures that your business remains resilient and protected. "  }
              
              </p>
          </div>
        </div>
      </section>

      {/* Staffing Services */}
      <section className="staffing-services">
        <div className="staffing-services-container">
          <div className="section-title">
            <h2 className='Staffing-Services'data-aos="fade-up">
            {data?.outsourcing_services?.staffing_services_title || " Outsourcing services"  }
             
              </h2>
            {/* <p className='staffing-content'>At Securoak, we understand that an organization's success hinges on its talent. Our Staffing Services are expertly crafted to connect you with skilled professionals who seamlessly integrate into your team, enhancing productivity from day one.</p> */}
          </div>

          {offerings.length > 0 ? (
            <div className="offerings offerings3">
              <img
                src={offerings[currentOffering].image}
                alt={offerings[currentOffering].title}
                className="offering-image"
              />
  
              <div className="offering offering3" data-aos="zoom-in-up">
                <h5>Key Offerings</h5>
                <h3 className='staffing-sub-titles'>{offerings[currentOffering].title}</h3>
                <p className='staffing-sub-para'>{offerings[currentOffering].description}</p>
              </div>
            </div>
          ) : (
            <p></p> 
          )}
  
          <div className="navigation navigation2"
     data-aos-anchor-placement="center-center">
            {/* Make sure the buttons trigger state change properly */}
            <button className="prev-btn" onClick={() => changeOffering('prev')}>Prev</button>
            <button className="next-btn  next-btn2" onClick={() => changeOffering('next')}>Next</button>
          </div>

        
        </div>

        <div className="cta">
            <h3>
            {data?.outsourcing_services?.title_2 || " Unlock Your Potential with Securoak" }
             
              </h3>
            <p>
            {data?.outsourcing_services?.content_2 ||  " Partnering with Securoak for outsourcing services empowers your organization to unlock its full potential. Our dedicated approach ensures that you can streamline operations, improve service delivery, and achieve your business objectives efficiently, all while maintaining a strong security posture."  }
             
              </p>
          </div>
      </section>


      <section className="unlocking-potential-section">
  <div className="container">
    <h2 className="section-title">{data?.outsourcing_services?.your_journey_main_title || "Your Journey with Us"}</h2>
    <p className="section-description">
      {data?.outsourcing_services?.your_journey_main_content || 
        "By choosing Securoak's WIAM solutions, you unlock a myriad of business benefits:"}
    </p>
    <div className="benefits-list">
      {data?.outsourcing_services?.your_journey_title?.map((title, index) => (
        <div 
          className="benefit-item" 
          data-aos="fade-up" 
          data-aos-delay={200 + index * 100} 
          key={index}
        >
          <div className="benefit-content">
            <img 
              src={data?.outsourcing_services?.logo?.[index] || "/default-image.png"} 
              alt={title} 
              className="benefit-image" 
            />
            <div className="benefit-text">
              <strong>{title}</strong><br />
              {data?.outsourcing_services?.your_journey_content?.[index] || "Default content."}
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
</section>





<section className="why-partner-with-us">
      <Row className="staff-container">
        {/* Left Side Content */}
        <Col md={12} sm={12} lg={6} className="content-left">
          <h2 data-aos="fade-down">
          {data?.outsourcing_services?.why_partner_title || " Why Partner with Us?"}
           
            </h2>
          <p data-aos="fade-up">
          {data?.outsourcing_services?.why_partner_content || " Choosing Securoak as your outsourcing partner means gaining a strategic ally focused on your success. Our commitment to quality, innovation, and personalized service ensures that you have the support needed to thrive in a competitive landscape. With our emphasis on cybersecurity and AI-driven insights, we work together to achieve operational excellence and drive sustainable growth for your organization." }
      
          </p>
        </Col>

        {/* Right Side Image */}
        <Col  md={12} sm={12} lg={6} className="content-right">
          <img src= {data?.outsourcing_services?.why_partner_image || "" } alt="Why Partner with Us" data-aos="fade-left"/>
        </Col>
      </Row>
    </section>



      <Footer />
    </>
  )
}
</>
);
}
export default Outsourcingservices;
