import "./Network-Telephony-Solutions.css"
import React from 'react'
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import banner from '../../components/assests/Outsourcing/networkservice.jpg';
import Assesment from "../../pages/IdentityGovernance/assesment.png";
import Strategy  from "../../pages/IdentityGovernance/stratagy.png";
import Implementation from "../../pages/IdentityGovernance/implimentation.png";
import Training from "../../pages/IdentityGovernance/training.png";
import partner from "../../pages/IdentityGovernance/partner.jpg";
import Support from "../../pages/IdentityGovernance/customer-service.png";

import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos'; // Import AOS
import  {useState, useEffect } from 'react';
import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import DOMPurify from 'dompurify';
import * as FaIcons from "react-icons/fa";
import Loader from "../../components/loader/loader";


const NetworkTelephonySolutions = () => {

  
  
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [itemsToDisplay, setItemsToDisplay] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}api/infrastructure-managed-services/network-and-telephony`
        );
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const result = await response.json();
        setData(result[0]);
      } catch (err) {
       
        console.error("API Fetch Error:", err.message);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    
    fetchData();
    AOS.init({ duration: 1000, once: true });
  }, []);


  const Key_Offer = data?.network_and_tech_key_offering?.map((item) => ({
    id: item.id,
    icon: item.icon || "FaShieldAlt", // Default image if `icon_image` is unavailable
    title: item.title || "Tailored Solutions", // Default title if `title` is unavailable
    content: item.content || "We customize our services to align with your specific business goals, ensuring optimal results while enhancing security.", // Default content
  })) || [];
  
  const netjourney = data?.network_and_tech_journey?.map((item) => ({
    id: item.id,
    image: process.env.REACT_APP_API +'uploads/'+ item.icon_image || "/default-image.png", // Default image if `icon_image` is unavailable
    title: item.title || "Tailored Solutions", // Default title if `title` is unavailable
    content: item.content || "We customize our services to align with your specific business goals, ensuring optimal results while enhancing security.", // Default content
  })) || [];
  

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Helmet>
            <title>{data?.meta_title || "Network & Telephony Solutions"}</title>
            <meta
              name="description"
              content={
                data?.meta_description || "Network & Telephony Solutions"
              }
            />
            <meta
              name="keywords"
              content={data?.meta_keywords || "Network & Telephony Solutions"}
            />
          </Helmet>
          <Header identityPage={true} />
          {/* Image and Overlay Section */}
          <div className="identity-image-container">
            <img
              src={data?.banner_image || banner}
              alt="A depiction of the company's philosophy"
              className="identity-page-image"
            />
            <div className="overlay-text">
              <h1>Network & Telephony Solutions </h1>
              <p>
                Home <i className="fa-solid fa-greater-than benner-icon"></i>{" "}
                Services{" "}
                <i className="fa-solid fa-greater-than benner-icon"></i>{" "}
                Infrastructure & Managed Services{" "}
                <i className="fa-solid fa-greater-than benner-icon"></i>Network
                & Telephony Solutions
              </p>
            </div>
          </div>

          <div className="noc-description">
            <p data-aos="zoom-out-up">
              {data?.content ||
                " Securoak’s Network Operations Center (NOC) and Security Operations Center (SOC) provide a comprehensive solution for managing IT infrastructure while enhancing security through advanced AI technologies. Our dedicated teams ensure your network operates seamlessly while proactively defending against cyber threats."}
            </p>
          </div>

          <h4 className="feature-heading">
            {data?.key_offerings || "key Offerings"}
          </h4>
          <Row className="align-items-center Features features2">
            {/* Feature Cards on the Left */}
            <Col md={12}>
              <div className="keyfeatures-container  keyfeatures-container2 container-fluid">
                <div className="features-list">
                  <Row className="identify-cards">
                    {Key_Offer.map((card, index) => {
                      // Get the corresponding icon component based on the icon name
                      const IconComponent = FaIcons[card.icon];
                      console.log("Icon name:", card.icon);
                      return (
                        <Col
                          key={card.id}
                          md={12}
                          className="feature-point"
                          data-aos={
                            index % 2 === 0 ? "fade-right" : "fade-left"
                          }
                        >
                          <div className="feature-icon"></div>
                          <div className="feature-text">
                            {IconComponent ? (
                              <IconComponent
                                size={30}
                                className="feature-icon"
                              />
                            ) : (
                              <i className="fas fa-question-circle"></i> // Fallback icon
                            )}
                            <strong>{card.title}:</strong>
                            <br />
                            {card.content}
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </div>
            </Col>
          </Row>

          <section className="unlocking-potential-section">
            <div className="container">
              <h2 className="section-title">
                {data?.journey_main_title || "Your Journey with Securoak"}
              </h2>
              <p className="section-description">
                {data?.journey_main_content ||
                  "By choosing Securoak's WIAM solutions, you unlock a myriad of business benefits:"}
              </p>

              {/* Journey Steps */}
              <div className="benefits-list">
                {netjourney.map((journey, index) => (
                  <div
                    className="benefit-item"
                    data-aos="fade-up"
                    data-aos-delay={200 + index * 100}
                    key={journey.id}
                  >
                    <div className="benefit-content">
                      <img
                        src={`${journey.image}`}
                        alt={journey.title}
                        className="benefit-image"
                      />
                      <div className="benefit-text">
                        <strong>
                          {journey.title ? journey.title : "Implementation"}
                        </strong>
                        <br />
                        {journey.content
                          ? journey.content
                          : "Our experts deploy tailored solutions that address your unique needs, ensuring seamless integration into your existing environment with minimal disruption."}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>

          <section className="why-partner-section" data-aos="fade-up">
            <div className="why-partner-top">
              <h2 className="why-partner-title">
                {data?.why_partner_title || " Why Partner with Securoak?"}
              </h2>
              <p className="why-partner-description">
                {data?.why_partner_main_content ||
                  " With Securoak’s network and telephony services, you ensure seamless communication and connectivity across your organization, all while maintaining stringent cybersecurity standards that protect your business."}
              </p>
            </div>

            <Row className="why-partner-content" data-aos="fade-up">
              <Col
                md={12}
                sm={12}
                lg={6}
                className="why-partner-image why-partner-image2 "
              >
                <img
                  src={data?.why_partner_image || partner}
                  alt="Securoak"
                  className="image mt-1"
                />
              </Col>

              <Col
                md={12}
                sm={12}
                lg={6}
                className="why-partner-features why-partner-features2"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      data?.why_partner_content ||
                        "Customized Solutions: Our services are tailored to your specific requirements, ensuring alignment with your business goals and supporting your long-term success."
                    ),
                  }}
                />
              </Col>
            </Row>

            <div className="securoak-benefit">
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    data?.why_partner_last_content ||
                      "Choose Securoak to empower your organization with a resilient IT infrastructure, comprehensive security posture, and the advanced capabilities of AI to thrive in a rapidly evolving digital landscape."
                  ),
                }}
              />
            </div>
          </section>

          <Footer />
        </>
      )}
    </>
  );
}

export default NetworkTelephonySolutions;
