import React, { useState } from "react";
import "./Managed-It-Services.css";
import Header from "../../components/header/header";
import banner from './itpro.jpg';
import Footer from "../../components/footer/footer";
import Assesment from "../../pages/IdentityGovernance/assesment.png";
import support from "./support.jpg";
import support2 from "./support2.jpg";
import it from "./itsupport2.jpg";
import Implementation from "../../pages/IdentityGovernance/implimentation.png";
import Training from "../../pages/IdentityGovernance/training.png";
import partner from "../../pages/IdentityGovernance/partner.jpg";
import Support from "../../pages/IdentityGovernance/customer-service.png";
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos'; // Import AOS
import  { useEffect } from 'react';
import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";
import onsite from "./onsite.jpg"

import { Helmet } from 'react-helmet';
import DOMPurify from 'dompurify';
import Loader from "../../components/loader/loader";


const ManagedItServices = () => {

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}api/infrastructure-managed-services/managed-it-services`
        );
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const result = await response.json();
        setData(result[0]);
      } catch (err) {
        console.error("API Fetch Error:", err.message);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    AOS.init({ duration: 1000, once: true });
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % services.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + services.length) % services.length);
  };



  // If data is null or missing required fields, show an appropriate message
  if (!data || !data.logo || !data.service_title || !data.service_content) {
    return <p></p>;
  }
  const services = data.logo.map((logo, index) => ({
    image: logo || onsite,
    title: data.service_title[index] || "Remote/Onsite Support",
    description:
      data.service_content[index] ||
      (index % 2 === 0
        ? `<ul>
             <li><strong>24/7 Availability:</strong>&nbsp;Continuous IT support with AI-driven tools to resolve issues efficiently.</li>
             <li><strong>Multi-Channel Access:</strong>&nbsp;Contact us via phone, email, or chat for convenient support.</li>
             <li><strong>Incident Management:</strong>&nbsp;AI-enhanced tracking ensures effective resolution.</li>
             <li><strong>Knowledge Base:</strong>&nbsp;Self-service resources empower users to resolve common issues.</li>
           </ul>`
        : `<ul>
             <li><strong>Remote Assistance:</strong>&nbsp;Rapid diagnosis using AI for predictive insights.</li>
             <li><strong>Onsite Visits:</strong>&nbsp;Hands-on support for complex troubleshooting.</li>
             <li><strong>Proactive Monitoring:</strong>&nbsp;Continuous monitoring prevents potential issues.</li>
             <li><strong>24/7 Availability:</strong>&nbsp;Round-the-clock access to support ensures your team is never without assistance.</li>
           </ul>`)
  }));
  
  // const services = [
  //   {
  //     image: support,
  //     title: "Service Desk Support",
  //     description: (
  //       <ul>
  //         <li><strong>24/7 Availability:</strong> Continuous IT support with AI-driven tools to resolve issues efficiently.</li>
  //         <li><strong>Multi-Channel Access:</strong> Contact us via phone, email, or chat for convenient support.</li>
  //         <li><strong>Incident Management:</strong> AI-enhanced tracking ensures effective resolution.</li>
  //         <li><strong>Knowledge Base:</strong> Self-service resources empower users to resolve common issues.</li>
  //       </ul>
  //     )
  //   },
  //   {
  //     image: support2,
  //     title: "Remote/Onsite Support",
  //     description: (
  //       <ul>
  //     <li><strong>Remote Assistance:</strong> Rapid diagnosis using AI for predictive insights.</li>
  //     <li><strong>Onsite Visits:</strong> Hands-on support for complex troubleshooting.</li>
  //     <li><strong>Proactive Monitoring:</strong> Continuous monitoring prevents potential issues.</li>
  //     <li><strong>24/7 Availability:</strong> Round-the-clock access to support ensures your team is never without assistance.</li>
     
  //   </ul>
  //     )
  //   },
  //   {
  //     image: it,
  //     title: "Private/Public Cloud Support",
  //     description: (
  //       <ul>
  //         <li><strong>Cloud Strategy:</strong> Customized migration strategies with security focus.</li>
  //         <li><strong>Deployment & Management:</strong> Seamless integration of cloud solutions.</li>
  //         <li><strong>Security & Compliance:</strong> Safeguard environments against threats.</li>
  //       </ul>
  //     )
  //   }
  // ];



  return (
    <>
    {loading ? (
      <Loader />
   ) : (
    <>

<Helmet>
  <title>{data?.meta_title || "Managed IT Services"}</title>
  <meta
    name="description"
    content={data?.meta_description || "Managed IT Services"}
  />
  <meta
    name="keywords"
    content={
      data?.meta_keywords ||
      "Customer Identity Management, Access Management, Workforce Identity, Secure Authentication, Identity Solutions"
    }
  />
</Helmet>

      <Header identityPage={true} />

      <div className="identity-image-container">
  
   
        <img src={data?.banner_image || banner  }alt="A depiction of the company's philosophy" className="identity-page-image" />
        <div className="overlay-text">
          <h1>Managed IT Services </h1>
          <p>Home <i className="fa-solid fa-greater-than benner-icon"></i> Services <i className="fa-solid fa-greater-than benner-icon"></i> Infrastructure & Managed Services <i className="fa-solid fa-greater-than benner-icon"></i>Managed IT Services</p>
        </div>
      </div>

      <div className="managedservice-description">
        <p className="managedservice-text"  data-aos="fade-up">
          {data?.main_content || "Securoak’s Managed IT Services empower organizations to optimize their technology infrastructure, ensuring seamless operations and enhanced productivity.Our solutions guarantee that your IT environment remains efficient, secure, and adaptable to your business needs, positioning you for sustained growth in today’s dynamic digital landscape."}
          
        </p>
      </div>
      <h2 className="section-title"  data-aos="fade-up">
        {data?.title || "Unlock Your Potential with Managed IT Services"}</h2>
          <p className="section-description section-description2" data-aos="fade-up">
            {data?.content || " Securoak's Managed IT Services enable organizations to harness technology for strategic growth and operational efficiency while integrating advanced AI and cybersecurity measures. Our comprehensive offerings ensure that your systems are robust, secure, and tailored to your unique business requirements."}
           
          </p>


          <section className="managed-it-services">
      <div className="managed-container">
        <div className="services" data-aos="fade-up">
          <Row className="service-row">
            {/* Left Column: Image */}
            <Col md={12} sm={12} lg={6} className="service-image">
              <img
                src={services[currentIndex].image}
                alt={services[currentIndex].title}
                className="service-icon img-fluid"
              />
            </Col>

            {/* Right Column: Content */}
            <Col md={12} sm={12} lg={6} className="service-content">
              <h3 className="service-title">{services[currentIndex].title}</h3>
              <div
                className="service-description"
                dangerouslySetInnerHTML={{ __html: services[currentIndex].description }}
              />

              {/* Navigation Buttons */}
              <div className="service-navigation mt-4">
                <button className="prev-btn btn btn-primary me-2" onClick={handlePrev}>
                  Prev
                </button>
                <button className="next-btn btn btn-primary" onClick={handleNext}>
                  Next
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </section>


    
    <section className="why-partner-section"  data-aos="fade-up">
      <div className="why-partner-top">
        <h2 className="why-partner-title">
       {data?. why_partner_title || " Why Partner with Securoak?"}
         </h2>
        <p className="why-partner-description why-partner-description2">
          {data?.why_partner_main_content || " With Securoak’s NOC and SOC services, you can rest assured that your IT infrastructure is secure, compliant, and performing at its peak."}
       
        </p>
      </div>

      <Row className="why-partner-content"  data-aos="fade-up">
        <Col  md={12} sm={12} lg={6}className="why-partner-image why-managed-it-img">
          <img src={ data?.why_partner_image || partner} alt="Securoak" className="image " style={{ marginTop: "-10px" }} />
        </Col>

        <Col md={12}  sm={12} lg={6} className="why-partner-features">
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              data?.why_partner_content || ""
            ),
          }}
        />
        </Col>
      </Row>

    
    </section>

      <Footer />
    </>
    
  )
}
</>
);
}
export default ManagedItServices
