import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./service.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "aos/dist/aos.css"; // Import AOS styles
import AOS from "aos"; // Import AOS
import identity from "../../assests/Outsourcing/infra2.jpg";
import application from "../../assests/Outsourcing/application2.jpg";
import staffing from "../../assests/Outsourcing/staffing.jpg";
import identityaccess from "../../assests/Outsourcing/access.jpg";
import Privileged from "../../assests/service/identity.jpg";
// import support from "../../assests/service/support.jpg";
// import support_service from "../../assests/service/dark.jpg";
import eye_one from "../../assests/service/dark.jpg";
import manage from "../../assests/service/dark.jpg";

import fallimage from "../../assests/service-2.jpg";

export default function Service({ data }) {
  const fallbackData = [
    {
      Image: identityaccess,
      ImageTwo:manage,
      Title: "IDENTITY SERVICES",
      Desc: "Encompass CIAM, WIAM, IG, PAM, Digital Identity Transformation, and Managed Support Services. These solutions enhance security, streamline access management, and ensure compliance while providing 24/7 support. Together, they empower organizations to protect sensitive data, optimize operations, and enhance customer and employee experiences in a secure environment.",
      Link: "/digitalidentity-transformation" ,
    },
    {
      Image: application, 
      ImageTwo: manage,
      Title: "Application Integration Services",
      Desc: "Provides seamless connectivity across your technology ecosystem, ensuring that all applications and data work in harmony. We specialize in creating intuitive user experiences through our UI/UX front-end development, combined with robust integration solutions that streamline workflows and enhance operational efficiency.",
      Link: "/application-integration-services",
    },
    {
      Image: identity,
      ImageTwo:manage,
      Title: "Infrastructure & Managed Services",
      Desc: "Delivers a robust suite of services, including IT consulting, NOC/SOC support, network and telephony solutions, managed IT services, and IT procurement. These offerings enhance operational efficiency, strengthen security, and ensure seamless connectivity, empowering organizations to achieve their business objectives while maintaining compliance and reducing risks.",
      Link: "/it-consulting",
    },
    {
      Image: staffing,
      ImageTwo: eye_one,
      Title: "Staffing & Outsourcing Services",
      Desc: "Empower organizations to streamline operations and focus on core competencies. Our tailored staffing solutions connect you with skilled professionals, while our outsourcing services manage non-core functions, enhancing efficiency and reducing costs. Together, we drive innovation and optimize resources for sustainable growth and success.",
      Link: "/staffing-services",
    },
  ];

  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with animation duration
  }, []);

  // Fallback image for missing images
  const largeImage = fallimage;

  const services = data?.Home_tesimonial
    ? [1, 2, 3, 4].map((serviceIndex) => ({
        Title: data.Home_tesimonial[`service_${serviceIndex}_title`],
        Desc: data.Home_tesimonial[`service_${serviceIndex}_content`],
        Link: data.Home_tesimonial[`service_${serviceIndex}_url`],
        Image: data.Home_tesimonial[`service_${serviceIndex}_front_image`]
          ? `${process.env.REACT_APP_API}${data.Home_tesimonial[`service_${serviceIndex}_front_image`]}`
          : largeImage,
        ImageTwo: data.Home_tesimonial[`service_${serviceIndex}_back_image`]
          ? `${process.env.REACT_APP_API}${data.Home_tesimonial[`service_${serviceIndex}_back_image`]}`
          : largeImage,
      }))
    : fallbackData;

  return (
    <section className="sec_pad" aria-labelledby="service-heading">
      <Container>
        <Row className="row-gap-small p-0">
          <Col md={12} sm={12} xs={12}>
            <div className="service_full">
              <div className="title" id="service-heading">
                {/* Title */}
              </div>
              <div className="sub_title">
                {data?.Home_tesimonial?.title || "What we Offer"}
              </div>
              <div className="cnt">
                <p>
                  {data?.Home_tesimonial?.content ||
                    "Securoak leads the way in digital security and IT innovation, offering tailored solutions that enhance performance, fortify resilience, and drive measurable outcomes. We empower businesses to thrive in an ever-evolving digital landscape with a strong emphasis on cybersecurity and AI."}
                </p>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="row-gap-small2 p-0">
          {services.map((service, index) => (
            <Col md={12} sm={12} lg={6} key={index}>
              <div className="service_section_box" data-aos="fade-up">
                <div
                  className="service_section_box_inner"
                  style={{
                    "--image-url": `url(${service.Image ||staffing})`,
                    "--image-two-url": `url(${service.ImageTwo || manage})`,
                  }}
                >
                  <div className="service_section_box_front">
                    <div className="title_only">
                      <div className="title">{service.Title || "Identity Services"}</div>
                    </div>
                  </div>
                  <div className="service_section_box_back">
                    <div className="text_color_white">
                      <div className="cnt">{service.Desc || 
                      "Provides seamless connectivity across your technology ecosystem, ensuring that all applications and data work in harmony. We specialize in creating intuitive user experiences through our UI/UX front-end development, combined with robust integration solutions that streamline workflows and enhance operational efficiency."}</div>
                      <div className="button-container">
                        <Link to={service.Link || "/"} className="cta-button">
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}
