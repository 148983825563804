import React, { useEffect, useState } from "react";
import "./footer.scss";
import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import logo from "../assests/footer-logo.png";
import cta_img from "../assests/cta.jpg";
import { FaFacebookSquare } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { IoLogoTwitter } from "react-icons/io";
import { AiFillForward } from "react-icons/ai";
import { IoLogoWhatsapp } from "react-icons/io";
import { MdOutgoingMail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";

export default function Footer() {
    const [itemsToDisplay, setItemsToDisplay] = useState([]);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API}api/header`
                );
                if (!response.ok)
                    throw new Error(`HTTP error! Status: ${response.status}`);
                const result = await response.json();
                console.log("API Response:", result); // Log to verify structure
                setData(result);
            } catch (err) {
                console.error("API Fetch Error:", err.message);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);
    const footeraddress = [{ line1: "" }];
   const Offer = [
     { Title: "Home", Link: "/" },
     { Title: "Our Services", Link: "/digitalidentity-transformation" },
     { Title: "Events", Link: "/upcoming-events" },
     { Title: "Contact Us", Link: "/contact-us" },
   ];
    return (
      <section className="footer_full" aria-labelledby="footer-heading">
        <div
          className="cta overlay-primary py-50 text-center text-lg-start"
          style={{
            backgroundImage: `url(${cta_img})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="container">
            <div className="row justify-content-between">
              <div
                className="col-lg-6"
                style={{
                  zIndex: 9,
                }}
              >
                <div className="text-white contact-inner">
                  <h2>
                    {data?.header_footer?.call_title_1 ||
                      "Contact us for Securoak Services"}
                  </h2>
                  <p className="footer-subtitle">
                    {data?.header_footer?.call_title_2 ||
                      "& Explore Securoak Solutions"}{" "}
                  </p>
                </div>
              </div>
              <div className="col-lg-6 text-lg-end align-self-center">
                <Link
                  to="/contact-us"
                  className="btn btn-light subscribe"
                  onClick={(e) => {
                    setTimeout(() => {
                      const element = document.getElementById("contact"); // Make sure the ID matches
                      if (element) {
                        element.scrollIntoView({
                          behavior: "smooth",
                        });
                      }
                    }, 0);
                  }}
                >
                  Contact us
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="banner">
          <Container>
            <Row>
              <Col md={12} sm={12} lg={4}>
                <div className="footer_image">
                  <div className="sizing">
                    <img
                      src={
                        data?.header_footer?.footer_logo
                          ? process.env.REACT_APP_API +
                            "uploads/logo/" +
                            data.header_footer.footer_logo
                          : logo // Fallback logo if footer_logo is not available
                      }
                      alt="Securoak Logo"
                      title="Securoak Logo"
                      loading="lazy"
                    />
                  </div>
                  <div className="cnt">
                    {data?.header_footer?.footer_content ||
                      " At Securoak, we are committed to delivering innovative technology solutions that empower businesses to thrive in today’s fast-paced digital landscape. "}
                  </div>

                  <div className="social_link">
                    {data?.header_footer?.facebook_link && (
                      <a
                        href={
                          data?.header_footer?.facebook_link ||
                          "https://facebook.com"
                        }
                        aria-label="Facebook"
                      >
                        <div className="facebook">
                          <FaFacebookSquare />
                        </div>
                      </a>
                    )}
                    {data?.header_footer?.linkedin_link && (
                      <a
                        href={
                          data?.header_footer?.linkedin_link ||
                          "https://linkedin.com"
                        }
                        aria-label="LinkedIn"
                      >
                        <div className="linkedin">
                          <FaLinkedin />
                        </div>
                      </a>
                    )}
                    {data?.header_footer?.insta_link && (
                      <a
                        href={
                          data?.header_footer?.insta_link ||
                          "https://www.instagram.com/"
                        }
                        aria-label="Instagram"
                      >
                        <div className="insta">
                          <FaInstagramSquare />
                        </div>
                      </a>
                    )}
                    {data?.header_footer?.youtube_link && (
                      <a
                        href={
                          data?.header_footer?.youtube_link ||
                          "https://www.youtube.com/"
                        }
                        aria-label="YouTube"
                      >
                        <div className="youtube">
                          <IoLogoYoutube />
                        </div>
                      </a>
                    )}
                    {data?.header_footer?.twitter_link && (
                      <a
                        href={
                          data?.header_footer?.twitter_link ||
                          "https://www.x.com"
                        }
                        aria-label="Twitter"
                      >
                        <div className="twitter">
                          <IoLogoTwitter />
                        </div>
                      </a>
                    )}
                  </div>
                </div>
              </Col>
              <Col md={12} sm={12} lg={4}>
                <div className="company_links">
                  <div className="title" id="footer-heading">
                    Quick Links
                  </div>
                  {Offer.map((item, nav) => (
                    <div className="specified_link" key={nav}>
                      <Link
                        to={item.Link}
                        aria-label={item.Title}
                        className="link-item"
                      >
                        <AiFillForward />
                        {item.Title}
                      </Link>
                    </div>
                  ))}
                </div>
              </Col>

              <Col md={12} sm={12} lg={4}>
                <div className="contact_info">
                  <div className="title">Contact Info</div>
                  <div className="address">
                    <span className="add-bold">
                      <span>
                        <FaLocationDot />
                      </span>
                      <span className="text austalia">
                        {data?.address[0]?.address
                          ? // If address exists and is a stringified JSON, parse it and split into lines
                            JSON.parse(data?.address[0]?.address).map(
                              (line, index) => <p key={index}>{line}</p>
                            )
                          : "Address will be update soon"}
                      </span>
                    </span>

                    <span className="add-bold">
                      <span>
                        <FaLocationDot />
                      </span>
                      <span className="text pt-2 chennai">
                        <span className="text australia">
                          {data?.address[1]?.address
                            ? // If address exists and is a stringified JSON, parse it and split into lines
                              JSON.parse(data?.address[1]?.address).map(
                                (line, index) => <p key={index}>{line}</p>
                              )
                            : "No address available"}
                        </span>
                      </span>
                    </span>
                  </div>

                  <div className="contact_one">
                    <a
                      href="tel:+61288971340"
                      className="phone_number"
                      aria-label="Call us at +61 28897 1340"
                    >
                      <span className="num_bold">
                        <span>
                          <IoLogoWhatsapp />
                        </span>
                        <span className="text">
                          <a
                            href={`tel:${
                              data?.header_footer?.mobile_no || "+61 28897 1340"
                            }`}
                            aria-label="Call us"
                          >
                            {data?.header_footer?.mobile_no
                              ? `+61 ${data.header_footer.mobile_no}`
                              : "+61 28897 1340"}
                          </a>
                        </span>
                      </span>
                    </a>
                  </div>

                  <div className="contact_two">
                    <a
                      href="mailto:contact@securoak.com"
                      className="mail_id"
                      aria-label="Email us at contact@securoak.com"
                    >
                      <span className="email_bold">
                        <span>
                          <MdOutgoingMail />
                        </span>

                        <span className="text">
                          <a
                            href={`mailto:${
                              data?.header_footer?.email ||
                              "contact@securoak.com"
                            }`}
                            aria-label="Email us"
                          >
                            {data?.header_footer?.email ||
                              "contact@securoak.com"}
                          </a>
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="divider_line"></div>

            <Row className="align-items-center">
              <Col md={6} sm={6} lg={4} className="split_divider">
                <div className="total_routes">
                  <div className="routes_pages">
                    <div className="policy_link">
                      <Link
                        to="/PrivacyPolicy"
                        onClick={(e) => {
                          setTimeout(() => {
                            const element = document.getElementById("privacy"); // Make sure the ID matches
                            if (element) {
                              element.scrollIntoView({
                                behavior: "smooth",
                              });
                            }
                          }, 0);
                        }}
                      >
                        <span className="Policy">Privacy Policy</span>
                      </Link>
                    </div>
                    <span className="line">|</span>
                    <div className="policy_link">
                      <Link
                        to="/Discaimer"
                        onClick={(e) => {
                          setTimeout(() => {
                            const element =
                              document.getElementById("disclaimer"); // Make sure the ID matches
                            if (element) {
                              element.scrollIntoView({
                                behavior: "smooth",
                              });
                            }
                          }, 0);
                        }}
                      >
                        <span className="Disclaimer">Disclaimer</span>
                      </Link>
                    </div>
                    <span className="line">|</span>
                    <div className="policy_link">
                      <Link
                        to="/TermnsandConditions"
                        onClick={(e) => {
                          setTimeout(() => {
                            const element = document.getElementById("terms"); // Make sure the ID matches
                            if (element) {
                              element.scrollIntoView({
                                behavior: "smooth",
                              });
                            }
                          }, 0);
                        }}
                      >
                        <span className="Terms">Terms & Conditions</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>

              <Row className="bottom align-items-center">
                {/* Left Column */}
                <Col md={6} sm={12}>
                  <div className="rights text-md-start text-center">
                    Copyright © 2024 Securoak. All Rights Reserved.
                  </div>
                </Col>

                {/* Right Column */}
                <Col md={6} sm={12}>
                  <div className="text-md-end text-center">
                    <a
                      target="_blank"
                      href="https://jayamwebsolutions.com/"
                      aria-label="Visit Jayam Web Solutions"
                      className="rights"
                    >
                      Developed by Jayam Web Solutions
                    </a>
                  </div>
                </Col>
              </Row>
            </Row>
          </Container>
        </div>
      </section>
    );
}
