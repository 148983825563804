import React from 'react'
import "./It-Consulting.css"
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import journey from '../innerdropdowns/journey.jpg';
import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";
import { FaUsers, FaChartLine, FaCogs, FaHandshake, FaLock } from "react-icons/fa";
import banner from '../WorkforceIdentity/workforce2.jpg';
import partner from "../../components/assests/partner.jpg"

import { FaShieldAlt, FaRegLightbulb,FaClipboardCheck,FaRocket, FaCloudUploadAlt,FaTachometerAlt, FaLayerGroup,  } from 'react-icons/fa';
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos'; // Import AOS
import  { useState , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import DOMPurify from 'dompurify';
import * as FaIcons from "react-icons/fa";
import Loader from "../../components/loader/loader";


const ItConsulting = () => {

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [itemsToDisplay, setItemsToDisplay] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}api/infrastructure-managed-services/it-consulting`
        );
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const result = await response.json();
        setData(result[0]);
      } catch (err) {
       
        console.error("API Fetch Error:", err.message);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    
    fetchData();
    AOS.init({ duration: 1000, once: true });
  }, []);

  const journeySteps = [
    { icon: <FaUsers />, title: "Consultation and Discovery", description: "Assessment:We conduct a thorough analysis of your current IT environment to identify areas for improvement, focusing on security vulnerabilities." },
    { icon: <FaChartLine />, title: "Strategic Planning", description: "Strategy Development: Collaborating with your team, we create a comprehensive IT strategy that drives efficiency and innovation while safeguarding sensitive data." },
    { icon: <FaCogs />, title: "Implementation", description: "Implementation: : Our experts oversee the deployment of AI-driven solutions, ensuring seamless integration with your existing systems and robust cybersecurity measures." },
    { icon: <FaHandshake />, title: "Ongoing Collaboration", description: "Continuous Improvement:  We provide ongoing support and optimization to adapt to your evolving needs, keeping pace with emerging threats and technological advancements." },
   
  ];
  const keyDifferenceData = data?.keydifference?.map((item) => ({
    id: item.id,          
    icon: item.icon || "FaShieldAlt",     
    title: item.title || "Tailored Solutions",     
    content: item.content || "We customize our services to align with your specific business goals, ensuring optimal results while enhancing security. ",
  })) || [];  // Default to an empty array if `data?.key_difference` is undefined
  
  
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Helmet>
            <title>{data?.meta_title || "IT Consulting"}</title>
            <meta
              name="description"
              content={data?.meta_description || "IT Consulting"}
            />
            <meta
              name="keywords"
              content={data?.meta_keywords || "IT Procurement"}
            />
          </Helmet>
          <Header identityPage={true} />
          {/* Image and Overlay Section */}
          <div className="identity-image-container">
            <img
              src={data?.banner_image || banner}
              alt="A depiction of the company's philosophy"
              className="identity-page-image"
            />
            <div className="overlay-text">
              <h1>IT Consulting </h1>
              <p>
                Home <i className="fa-solid fa-greater-than benner-icon"></i>{" "}
                Services{" "}
                <i className="fa-solid fa-greater-than benner-icon"></i>{" "}
                Infrastructure & Managed Services{" "}
                <i className="fa-solid fa-greater-than benner-icon"></i> IT
                Consulting
              </p>
            </div>
          </div>

          <section>
            <div class="it-discription" id="it-consulting">
              <p data-aos="zoom-out-up">
                {data?.content ||
                  "Securoak provides an extensive range of IT services designed to optimize organizational performance and enhance cybersecurity. Our offerings include IT consulting, NOC/SOC support, network and telephony solutions, managed IT services, and IT procurement. By integrating advanced AI technologies, we streamline operations, bolster security measures, and ensure compliance, empowering businesses to navigate the complexities of today’s digital landscape while mitigating risks effectively. With a focus on delivering tailored solutions, Securoak enables organizations to achieve their strategic objectives and maintain seamless connectivity."}
              </p>
            </div>

            <h4 className="feature-heading">Key Differentiators of (WIAM)</h4>
            <Row className="align-items-center Features features2">
              {/* Feature Cards on the Left */}
              <Col md={12}>
                <div className="keyfeatures-container  keyfeatures-container2 container-fluid">
                  <div className="features-list">
                    <Row className="identify-cards">
                      {keyDifferenceData.map((card, index) => {
                        // Get the corresponding icon component based on the icon name
                        const IconComponent = FaIcons[card.icon];
                        console.log("Icon name:", card.icon);
                        return (
                          <Col
                            key={card.id}
                            md={12}
                            className="feature-point"
                            data-aos={
                              index % 2 === 0 ? "fade-right" : "fade-left"
                            }
                          >
                            <div className="feature-icon"></div>
                            <div className="feature-text">
                              {IconComponent ? (
                                <IconComponent
                                  size={30}
                                  className="feature-icon"
                                />
                              ) : (
                                <i className="fas fa-question-circle"></i> // Fallback icon
                              )}
                              <strong>{card.title}:</strong>
                              <br />
                              {card.content}
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>

            <Card
              className="mb-4 potential-card m-3 card aos-init aos-animate "
              data-aos="fade-up"
            >
              <h2 className="text-center pt-3 journey-title">
                {data?.journey_main_title || "Journey with Us"}
              </h2>
              <Card.Body>
                <Row>
                  {/* Content Column */}
                  <Col md={12} sm={12} className="content-column">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          data?.journey_main_content ||
                            "Collaborating with your team, we create a comprehensive IT strategy that drives efficiency and innovation while safeguarding sensitive data."
                        ),
                      }}
                    />
                  </Col>

                  {/* Image Column */}
                  <Col md={12} sm={12} className="image-column text-center">
                    <img
                      src={`${process.env.REACT_APP_API}uploads/services/${
                        data?.journey_logo || journey
                      }`}
                      alt="Journey"
                      className="big-image"
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <section className="why-partner-section" data-aos="fade-up">
              <div className="container">
                {/* Section Top Content */}
                <div className="why-partner-top text-center mb-4">
                  <h2 className="why-partner-title">
                    {data?.why_partner_title || "Why Partner with Securoak?"}
                  </h2>
                  <p className="why-partner-description">
                    {data?.why_partner_main_content ||
                      " Transform your IT strategy with Securoak, leveraging AI and cybersecurity to drive sustainable growth for your organization."}
                  </p>
                </div>

                <Row className="align-items-center">
                  <Col
                    md={12}
                    sm={12}
                    lg={6}
                    className="text-center mb-4 mb-md-0"
                  >
                    <img
                      src={data?.why_partner_image || partner}
                      alt="Securoak"
                      className="img-fluid why-partner-image"
                    />
                  </Col>

                  {/* Features Column */}
                  <Col md={12} sm={12} lg={6}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          data?.why_partner_content ||
                            "Strategic Planning: Together, we develop a comprehensive roadmap that outlines the key steps in your digital identity transformation journey."
                        ),
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </section>
          </section>
          <Footer />
        </>
      )}
    </>
  );
}

export default ItConsulting;
