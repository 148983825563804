import React from 'react'
import "./Application-Integration-Services.css"
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import securoakImage from '..//../components/assests/priviledge/security2.jpg';
import banner from "../../components/assests/api/apibanner.jpg";
import api from "../../components/assests/api/api.png";
import api2 from "../../components/assests/api/api2.png";
import devops from "../../components/assests/api/devops.png";
import ux from "../../components/assests/api/uxdeasign.png";
import product from "../../components/assests/api/product4.png";
import { Card, ListGroup } from 'react-bootstrap'; // Assuming you're using Bootstrap for card and list styling
import { useState,useEffect } from 'react';
import img1 from './img1.jpg'
import img2 from './img2.jpg'
import img3 from './img3.jpg'
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos'; // Import AOS

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Helmet } from 'react-helmet';
import DOMPurify from 'dompurify';
import Loader from "../../components/loader/loader";


const ApplicationIntegrationServices = () => {


 // Declare state hooks at the top level of the component
 const [itemsToDisplay, setItemsToDisplay] = useState([]);
 const [loading, setLoading] = useState(true);
 const [data, setData] = useState(null);
 const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}api/application-integration-services`);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const result = await response.json();
        console.log("API Response:", result); // Log API response for debugging
        setData(result[0]);
      } catch (err) {
        console.error('API Fetch Error:', err.message);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
   
    fetchData();
    AOS.init({ duration: 1000, once: true }); // Initialize AOS
  }, []);


  
  // Update itemsToDisplay after data is loaded
  useEffect(() => {
    if (data) {
      const items = [1, 2, 3, 4].map((i) => ({
        title: data["title_" + i],
        text: data["content_" + i],
        image: data["image_" + i],
      }));
      setItemsToDisplay(items);
    }
  }, [data]); // Only run when 'data' changes

  

  return (
    <>
    {loading ? (
      <Loader />
   ) : (
    <>

       <Helmet>
        <title>Application Integration Services</title>
        <meta name="description" content="Application Integration Service" />
        
      </Helmet>


 <Header identityPage={true} />
      <div>
        {/* Banner Section */}
        <div className="identity-image-container">
          <img
            src={data?.banner_image || banner}
            alt="Banner"
            className="identity-page-image"
          />
          <div className="overlay-text">
            <h1>{data?.meta_title || "Application Integration Services"}</h1>
          
            <p>
              Home <i className="fa-solid fa-greater-than benner-icon"></i> Services{' '}
              <i className="fa-solid fa-greater-than benner-icon"></i>{' '}
              {data?.meta_title || "Application Integration Services"}
            </p>
          </div>
        </div>
      </div>


      <section id="application-integration" data-aos="fade-up">
        <div className="application-integration">
          <div className="container">
            <p className="integration-description">
              {data?.content || "Default integration description goes here."}
            </p>
          </div>
        </div>
      </section>


 
    {/* Unlocking Your Potential section */}

    <section className="keys-section" data-aos="fade-up"  >
  <div className="keys-container">
  <h2 className="keys-section-title">{data?.key_feature_main_title || 'Key Features'}</h2>
  
    <p className="keys-section-description">
    {data?.key_feature_main_content || "  By choosing Securoak's WIAM solutions, you unlock a myriad of business benefits:" }
    
    </p>

    
    <div className="keys-list">
  {data?.key_feature_title?.map((title, index) => (
    <div 
      className="keys-benefit-item" 
      data-aos="fade-up" 
      data-aos-delay={200 + index * 100} 
      key={index}
    >
      <div className="keys-benefit-content">
        <img 
          src={data?.logo?.[index] || "/default-image.png"} 
          alt={title} 
          className="keys-benefit-image benefit-image" 
        />
        <div className="keys-benefit-text">
          <strong>{title}</strong><br />
          {data?.key_feature_content?.[index] || "Default content."}
        </div>
      </div>
    </div>
  ))}
</div>

  </div>
</section>


<section className="journey-with-securoak" data-aos="fade-up">
      <h2 className="text-center journey-title">{data?.journey_title}</h2>
      <p className="title-below-para">{data?.journey_content}</p>
      <div className="container pb-5">
        {itemsToDisplay.map((item, index) => (
          <div
            key={index}
            className={`card-main d-flex mt-4 ${
              index % 2 === 0 ? "flex-row-reverse" : "flex-row"
            }`}
          >
            <div className="content flex-grow-1 d-flex flex-column align-items-center justify-content-start">
              <h4>{item.title}</h4>
              <div className="list-content">
                <span>{item.text}</span>
              </div>
            </div>
            <div className="card-image d-flex flex-column align-items-center justify-content-start">
              <img
                src={item.image}
                className="unlock-img"
                alt={item.title}
              />
            </div>
          </div>
        ))}
      </div>
    </section>

    

    <section className="why-securoak-section " data-aos="fade-up">
      <div className="why-securoak-top">
        <h2 className="why-securoak-title">
          {data?.why_choose_title || "Why Choose Securoak"}</h2>
        <p className="why-securoak-description">
        {data?.why_choose_content || "  With Securoak, you enhance your digital identity management capabilities and position your organization as a trusted leader in the marketplace. Let’s unlock your potential together." }
        
        </p>
      </div>

      <Row className="why-securoak-content">
        <Col md={12} sm={12} lg={6} className="why-securoak-image application-integration">
          <img src={data?.why_choose_image ||securoakImage} alt="Securoak" className="image" />
        </Col>

        <Col  md={12} sm={12} lg={6} className="why-securoak-features">
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              data?.content_editor || ""
            ),
          }}
        />
        </Col>
      </Row>
    </section>
    <Footer />
    </>
  )
}
</>
);
}

export default ApplicationIntegrationServices;
