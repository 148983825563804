import React from 'react';
import {useState , useEffect } from 'react';
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import banner from '../WorkforceIdentity/workforce2.jpg';
import "./IdentityGovernance.css"
import Assesment from "./assesment.png";
import Strategy  from "./stratagy.png";
import Implementation from "./implimentation.png";
import Training from "./training.png";
import partner from "./partner.jpg";
import Support from "./customer-service.png";
import img1 from './img1.jpg'
import img2 from './img2.jpg'
import img3 from './img3.jpg'
import banner1 from './gov2.jpg'
import { Card, ListGroup } from 'react-bootstrap'; // Assuming you're using Bootstrap for card and list styling
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos'; // Import AOS
import { Helmet } from 'react-helmet';
import DOMPurify from 'dompurify';
import Loader from "../../components/loader/loader";

const IdentityGovernance = () => {

  
  const [itemsToDisplay, setItemsToDisplay] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}api/identify-offerings`);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const result = await response.json();
        console.log("API Response:", result); // Log to verify structure
        setData(result);
      } catch (err) {
        console.error("API Fetch Error:", err.message);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    AOS.init({ duration: 1000, once: true });
  }, []);

  useEffect(() => {
    if (data) {
      // Dynamically create items based on the available data keys
      const items = Object.keys(data?.identity_governance_with_securoaks || {})
        .filter((key) => key.startsWith("title_")) // Filter out keys starting with 'title_'
        .map((key, index) => {
          const i = index + 1; // Assuming your titles start from title_1, content_1, image_1
          const title = data?.identity_governance_with_securoaks?.[`title_${i}`];
          const text = data?.identity_governance_with_securoaks?.[`content_${i}`];
          const image = data?.identity_governance_with_securoaks?.[`image_${i}`];

          if (!title || !text || !image) {
            console.warn(`Missing data for item ${i}:`, { title, text, image });
          }

          return {
            title: title || `Default Title ${i}`,
            text: text || `Default Content ${i}`,
            image: image || "/default-image.jpg",
          };
        });

      console.log("Mapped Items After Fallback:", items);
      setItemsToDisplay(items);
    }
  }, [data]);

  // if (loading) return <div className="loading">Loading...</div>;
  // if (error) return <div className="error">Error: {error}</div>;

    const potentialItems = [
        {
          image:img1, title:"Streamlined Risk Management",text: " Our advanced AI-driven tools allow you to understand and manage access risks effortlessly. By leveraging sophisticated algorithms, we continuously analyze user behavior patterns and access requests, enabling you to control permissions for sensitive data and protect your organization’s most valuable resources. This proactive approach not only safeguards your data but also ensures compliance with ever-evolving regulatory requirements."
        },
        {
          image:img2, title:"Proactive Policy Enforcement",text: " With automated identification of policy violations, our IG solutions enable you to address risks before they escalate. Our real-time detection capabilities, powered by machine learning, monitor access patterns and user activities around the clock. This allows you to prevent unauthorized access and compliance issues, fortifying your systems and safeguarding sensitive information from potential breaches."
        },
        {
          image:img3, title:"Expert Implementation with Trusted Partners",text: " We collaborate with leading providers such as SailPoint and RSA, combining their industry-leading technology with our deep technical expertise tailored to your identity governance needs. By integrating advanced cybersecurity measures, we enhance security while minimizing operational risks, ensuring that your identity management framework is resilient against modern threats."
        },
        {
          image:img1, title:"Streamlined Risk Management",text: " Our advanced AI-driven tools allow you to understand and manage access risks effortlessly. By leveraging sophisticated algorithms, we continuously analyze user behavior patterns and access requests, enabling you to control permissions for sensitive data and protect your organization’s most valuable resources. This proactive approach not only safeguards your data but also ensures compliance with ever-evolving regulatory requirements."
        },
        {
          image:img2, title:"Proactive Policy Enforcement", text: " With automated identification of policy violations, our IG solutions enable you to address risks before they escalate. Our real-time detection capabilities, powered by machine learning, monitor access patterns and user activities around the clock. This allows you to prevent unauthorized access and compliance issues, fortifying your systems and safeguarding sensitive information from potential breaches."
        },
        {
          image:img3, title:"Expert Implementation with Trusted Partners",text: " We collaborate with leading providers such as SailPoint and RSA, combining their industry-leading technology with our deep technical expertise tailored to your identity governance needs. By integrating advanced cybersecurity measures, we enhance security while minimizing operational risks, ensuring that your identity management framework is resilient against modern threats."
        },
       
       
      ];
      // const potentialItems2 = [
      //   {
      //     text: "Streamlined Risk Management: Our advanced AI-driven tools allow you to understand and manage access risks effortlessly. By leveraging sophisticated algorithms, we continuously analyze user behavior patterns and access requests, enabling you to control permissions for sensitive data and protect your organization’s most valuable resources. This proactive approach not only safeguards your data but also ensures compliance with ever-evolving regulatory requirements."
      //   },
      //   {
      //     text: "Proactive Policy Enforcement: With automated identification of policy violations, our IG solutions enable you to address risks before they escalate. Our real-time detection capabilities, powered by machine learning, monitor access patterns and user activities around the clock. This allows you to prevent unauthorized access and compliance issues, fortifying your systems and safeguarding sensitive information from potential breaches."
      //   },
      //   {
      //     text: "Expert Implementation with Trusted Partners: We collaborate with leading providers such as SailPoint and RSA, combining their industry-leading technology with our deep technical expertise tailored to your identity governance needs. By integrating advanced cybersecurity measures, we enhance security while minimizing operational risks, ensuring that your identity management framework is resilient against modern threats."
      //   },
       
      // ];


  return (
    <>
    {loading ? (
      <Loader />
   ) : (
    <>
     <Helmet>
        <title> {data?.identity_governance_with_securoaks?.meta_title || "Identity Governance with Securoak" }  </title>
        <meta name="description" content= {data?.identity_governance_with_securoaks?.meta_description || "Identity Governance with Securoak"}/>
        <meta
        name="keywords"
        content= {data?.identity_governance_with_securoaks?.meta_keywords || "Customer Identity Management, Access Management, Workforce Identity, Secure Authentication, Identity Solutions " } 
      />
        
      </Helmet>
      <Header identityPage={true} />

        {/* Image and Overlay Section */}
        <div className="identity-image-container">
        <img
          src={data?.identity_governance_with_securoaks?.banner_image ||banner1}
          alt="A depiction of the company's philosophy"
          className="identity-page-image"
        />
        <div className="overlay-text">
          <h1>Identity Governance with Securoak </h1>
          <p>Home <i className="fa-solid fa-greater-than benner-icon"></i>services<i className="fa-solid fa-greater-than benner-icon"></i> idendity goverence <i className="fa-solid fa-greater-than benner-icon"></i> (WIAM)</p>
        </div>
      </div>

      <div className="identity-governance-container" data-aos="fade-up">
        {/* <h1 className="identity-governance-title">Identity Governance with Securoak</h1> */}
        <p className="identity-governance-description">
        {data?.identity_governance_with_securoaks?.content || " Securoak’s Identity Governance (IG) solutions provide a robust framework that seamlessly integrates people, applications, data, and devices within your organization. In an era where AI and cybersecurity intersect, our solutions offer clear visibility over access permissions and associated risks, empowering you to manage security and compliance effectively in today’s complex digital landscape." }
       
        </p>
        {/* <p className="identity-governance-description">
          Empower your organization to manage security, compliance, and operational efficiency effectively. With Securoak, you gain the tools needed to navigate the complexities of access control, ensuring that your digital assets are protected and aligned with regulatory requirements.
        </p> */}
      </div>

      <section className="journey-with-securoak" data-aos="fade-up">
      <h2 className="text-center journey-title">
        {data?.identity_governance_with_securoaks?.journey_title || "Unlocking Your Potential"}
      </h2>
      {/* <p className="title-below-para">
        {data?.identity_governance_with_securoaks?.journey_content || "Default content."}
      </p> */}
      <div className="container pb-4">
        {itemsToDisplay.length > 0 ? (
          itemsToDisplay.map((item, index) => (
            <div
              key={index}
              className={`card-main d-flex mb-1 ${index % 2 === 0 ? "flex-row-reverse" : "flex-row"}`}
            >
              <div className="content flex-grow-1 d-flex flex-column align-items-center justify-content-start">
                <h4>{item.title || "Streamlined Risk Management"}</h4>
                <div className="list-content">
                  <span>{item.text || "With automated identification of policy violations, our IG solutions enable you to address risks before they escalate. Our real-time detection capabilities, powered by machine learning, monitor access patterns and user activities around the clock. This allows you to prevent unauthorized access and compliance issues, fortifying your systems and safeguarding sensitive information from potential breaches."}</span>
                </div>
              </div>
              <div className="card-image d-flex flex-column align-items-center justify-content-start">
                <img
                  src={item.image}
                  className="unlock-img"
                  alt={item.title}
                  onError={(e) => (e.target.src = "./j1.jpg")}
                />
              </div>
            </div>
          ))
        ) : (
          <div></div>
        )}
      </div>
    </section>

    {/* <section className="journey-with-securoak">
    <div className='text-center'>
  <h2 className="c journey-title">Unlocking Your Potential</h2>
  <p className='title-below-para'>Partnering with Securoak for your WIAM journey means embracing a structured approach to identity management that unlocks your organization’s full potential.</p>
</div>

      <Card className="mb-4 journey-card">
        <Card.Body className="d-flex flex-row">
          <div className="content-column">
            <ListGroup variant="flush">
              {potentialItems.map((item, index) => (
                <ListGroup.Item key={index} className="list-item">
                  <div className="list-icon">{item.icon}</div>
                  <span>{item.text}</span>
                </ListGroup.Item>
              ))}
            </ListGroup>
           
          </div>

          <div className="image-column">
          <dotlottie-player 
    src="https://lottie.host/b6f681e0-7777-4f16-83f5-b6f2bb35c65a/ExvDWUEra1.json"
    background="transparent"
    speed="1"
    className="potential-lottie"
    loop
    autoplay
  ></dotlottie-player>
          </div>
        </Card.Body>
      </Card>
    </section> */}





    {/* Unlocking Your Potential section */}

    <section className="unlocking-potential-section">
  <div className="container">
    <h2 className="section-title">{data?.identity_governance_with_securoaks?.key_feature_main_title || "Your Journey with Securoak"}</h2>
    <p className="section-description">
      {data?.identity_governance_with_securoaks?.key_feature_main_content || 
        "By choosing Securoak's WIAM solutions, you unlock a myriad of business benefits:"}
    </p>
    <div className="benefits-list">
      {data?.identity_governance_with_securoaks?.your_journey_title?.map((title, index) => (
        <div 
          className="benefit-item" 
          data-aos="fade-up" 
          data-aos-delay={200 + index * 100} 
          key={index}
        >
          <div className="benefit-content">
            <img 
              src={data?.identity_governance_with_securoaks?.logo?.[index] || "/default-image.png"} 
              alt={title} 
              className="benefit-image" 
            />
            <div className="benefit-text">
              <strong>{title || "Strategy Development:"}</strong><br />
              {data?.identity_governance_with_securoaks?.your_journey_content?.[index] || "Collaborating with your team, we design a customized identity governance strategy that aligns with your business objectives and security needs, integrating advanced AI techniques for predictive analytics."}
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
</section>




<section className="why-partner-section" data-aos="fade-up">
  <div className="why-partner-top text-center mb-4">
    <h2 className="why-partner-title">
   
   {data?.identity_governance_with_securoaks?. why_partner_title || "Why Partner with Securoak?"} </h2>
    <p className="why-partner-description">
   { data?.identity_governance_with_securoaks?.why_partner_content || "With Securoak, you enhance your digital identity management capabilities and position your organization as a trusted leader in the marketplace. Let’s unlock your potential together."}
      
    </p>
  </div>

  <Row className="align-items-center why-partner-content" data-aos="fade-up">
    {/* Image Column */}
    <Col md={12} sm={12} lg={6} className="why-partner-image text-center mb-4 mb-md-0">
   
      <img src= {data?.identity_governance_with_securoaks?.why_choose_image|| partner} alt="Securoak" className="img-fluid" />
    </Col>

    {/* Features Column */}
    <Col md={12} sm={12}  lg={6} className="why-partner-features">
    <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              data?.identity_governance_with_securoaks?.content_editor || ""
            ),
          }}
        />
    </Col>
  </Row>
</section>


    <div className="securoak-benefit" data-aos="fade-up">
    <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              data?.identity_governance_with_securoaks?.additional_content|| ""
            ),
          }}
        />
</div>






      <Footer />
    </>
  )
};
</>
);
}
export default IdentityGovernance;
