import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { IoStarSharp } from "react-icons/io5";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AOS from 'aos'; // Import AOS
import 'aos/dist/aos.css'; // Import AOS styles
import "./review.scss";

export default function Review({ data }) {


  const reviewdata = [
    {
      quote : `"I am immensely satisfied with the collaboration with Securoak. Their expertise, commitment, and support throughout the project have been invaluable. Together, we have transformed our identity governance framework, and I wholeheartedly recommend them for any future projects."`,
      author : "— David Fiorina, Head of Cyber Security, Bunnings",
      summary :"In Summary: Through our strategic partnership, Securoak empowered the client to secure their identities effectively and enhance their governance framework, laying the foundation for a more secure and compliant future."




    }
  ]
  useEffect(() => {
    AOS.init({ duration: 1000, once: true }); // Initialize AOS
  }, []);

  const [liked, setLiked] = useState(false);

  const handleLike = () => {
    setLiked(true);
    toast.success('Thank you for liking the review!', {
      position: "bottom-right",
      autoClose: 2000,
    });
    setTimeout(() => setLiked(false), 2000);
  };

  return (
    <section className="review-section">
      <Container fluid className="review-container">
        <Row className="justify-content-center">
          <Col md={12} className="text-center">
            {/* Dynamic Section Title */}
            <h2 className="title">{data?.Home_event?.section_title || "Client Testimonial"}</h2>
            {/* Dynamic Testimonial Title */}
            <p className="desc">{data?.Home_event?.title || "Securing 60,000+ Identities with Saviynt"}</p>

            {/* Dynamic Star Rating */}
            <div className="stars-stars">
              {[...Array(data?.Home_event?.stars_count || 5)].map((_, i) => (
                <IoStarSharp key={i} style={{ color: '#f47721', fontSize: '20px' }} />
              ))}
            </div>

            {/* Dynamic Testimonial Content */}
            <div className="testimonial">
              <p className="quote" data-aos="fade-up">
                {data?.Home_event?.content ? (
                  <div
                    className="quote-content"
                    dangerouslySetInnerHTML={{ __html: data?.Home_event?.content|| "" }}
                  />
                ) : (
                  "I am immensely satisfied with the collaboration with Securoak. Their expertise, commitment, and support throughout the project have been invaluable. Together, we have transformed our identity governance framework, and I wholeheartedly recommend them for any future projects."
                  
                )}
              </p>

              
            </div>
          </Col>
        </Row>

       
      
      </Container>

      <ToastContainer />
    </section>
  );
}
