import React from "react";
import "./identity.scss";
import "./identity.css";
import { Link } from "react-router-dom";
import Loader from "../components/loader/loader";
import * as FaIcons from "react-icons/fa";
import banner from '../components/assests/custoban2.jpg';
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "@fortawesome/fontawesome-free/css/all.min.css";
import abt_img from "../components/assests/intel.jpg";
import { FaArrowRightLong } from "react-icons/fa6";
import JourneyWithSecuroak from "./JourneyWithSecuroak/JourneyWithSecuroak";
import WhyChooseSecuroak from "./WhyChooseSecuroak/WhyChooseSecuroak";
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos'; // Import AOS
import  { useState , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";


const Identity = () => {




  const [itemsToDisplay, setItemsToDisplay] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}api/identify-offerings`);
        // if (!response.ok) {
        //   throw new Error(`HTTP error! Status: ${response.status}`);
        // }
        const result = await response.json();
        setData(result); // Update state with API response
      } catch (error) {
        // setError(error.message); 
      } finally {
        setLoading(false); // Stop loading indicator
      }
    };
  
    fetchData();
    AOS.init({ duration: 1000, once: true }); // Initialize AOS
  }, [])

  useEffect(() => {
    if (data) {
      // Dynamically create items based on the available data keys
      const items = Object.keys(data?.customer_identity_access_management || {})
        .filter((key) => key.startsWith("title_")) // Filter out keys starting with 'title_'
        .map((key, index) => {
          const i = index + 1; // Assuming your titles start from title_1, content_1, image_1
          const title = data?.customer_identity_access_management?.[`title_${i}`];
          const text = data?.customer_identity_access_management?.[`content_${i}`];
          const image = data?.customer_identity_access_management?.[`image_${i}`];

          if (!title || !text || !image) {
            console.warn(`Missing data for item ${i}:`, { title, text, image });
          }

          return {
            title: title || `Default Title ${i}`,
            text: text || `Default Content ${i}`,
            image: image || "/default-image.jpg",
          };
        });

      console.log("Mapped Items After Fallback:", items);
      setItemsToDisplay(items);
    }
  }, [data]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
 // Use the provided data or fallback to defaultData
 

 return (
  <>
    {loading ? (
       <Loader />
    ) : (
      <>
        <Helmet>
          <title>
            {data?.customer_identity_access_management?.meta_title ||
              "Customer Identity and Access Management"}
          </title>
          <meta
            name="description"
            content={
              data?.customer_identity_access_management?.meta_description ||
              "Customer Identity and Access Management"
            }
          />
          <meta
            name="keywords"
            content={
              data?.customer_identity_access_management?.meta_keywords ||
              "Customer Identity Management, Access Management, Workforce Identity, Secure Authentication, Identity Solutions"
            }
          />
        </Helmet>

        <div className="identity-page" id="identify">
          <Header identityPage={true} />

          {/* Image and Overlay Section */}
          <div className="identity-image-container">
            <img
              src={
                data?.customer_identity_access_management?.banner_image || banner
              }
              alt="A depiction of the company's philosophy"
              className="identity-page-image"
            />
            <div className="overlay-text">
              <h1>Customer Identity and Access Management</h1>
              <p>
                Home <i className="fa-solid fa-greater-than benner-icon"></i>{" "}
                Services{" "}
                <i className="fa-solid fa-greater-than benner-icon"></i>{" "}
                Identify Offering Customer{" "}
                <i className="fa-solid fa-greater-than benner-icon"></i> (CIAM)
              </p>
            </div>
          </div>

          {/* Main Content Section */}
          <section className="content-section">
            <Container fluid>
              <div className="content-container">
                <div className="section-header sec-start"></div>

                <p className="section-description " data-aos="fade-up">
                  {data?.customer_identity_access_management?.main_content ||
                    " At Securoak, we provide cutting-edge Customer Identity and Access Management (CIAM) solutions that empower your customers to securely manage their personal information."}
                </p>

                <div
                  className="unlocking-digital-section"
                  data-aos="fade-up"
                >
                  <h3 className="unlocking-digital-title">
                    {data?.customer_identity_access_management?.title ||
                      "  Unlocking Your Digital Potential"}
                  </h3>

                  <p className="unlocking-digital-description">
                    {data?.customer_identity_access_management?.content ||
                      "Partnering with Securoak unlocks your organization’s full potential in the digital landscape."}
                  </p>
                </div>

                <h4 className="feature-heading">
                  {" "}
                  {data?.customer_identity_access_management
                    ?.key_feature_main_title ||
                    "Key Features that Empower Your Business"}
                </h4>
                <Row className="align-items-center p-2">
                  {/* Key Features Section */}
                  <Col md={12} sm={12} lg={6}>
                    <div className="keyfeatures-container" data-aos="fade-up">
                      <div className="features-list">
                        <Row
                          className="align-items-center "
                          data-aos="fade-up"
                        >
                          <Col md={12}>
                            <div className="keyfeatures-container container-fluid">
                              <div className="features-list">
                                <Row className="identify-cards">
                                  {data?.customer_identity_access_management?.key_feature_title.map(
                                    (title, index) => {
                                      const iconName = data
                                        .customer_identity_access_management
                                        .logo[index]
                                        .split("/")
                                        .pop();

                                      const IconComponent =
                                        FaIcons[iconName];

                                      return (
                                        <Col
                                          md={12} sm={12}
                                          className="feature-point"
                                          key={index}
                                        >
                                          <div className="feature-icon">
                                            {IconComponent && (
                                              <IconComponent
                                                size={30}
                                               
                                              />
                                            )}
                                          </div>
                                          <div className="feature-text">
                                            <strong>
                                              {title ||
                                                "Strong Authentication:"}
                                            </strong>
                                            <br />
                                            {
                                              data.customer_identity_access_management.key_feature_content[
                                                index
                                              ]
                                            }
                                          </div>
                                        </Col>
                                      );
                                    }
                                  )}
                                </Row>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>

                  {/* About Image Section */}
                  <Col md={12} sm={12} lg={6} className="text-center">
                    <div className="about-image">
                      <img
                        className="keyfeatureimg"
                        src={abt_img || abt_img}
                        alt="About Us"
                        title="About Our Company"
                        loading="lazy"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </section>

          <JourneyWithSecuroak />
          <WhyChooseSecuroak />

          <Footer />
        </div>
      </>
    )}
  </>
);
}
export default Identity;