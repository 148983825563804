import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./video.scss";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import video from "../video/Securoak_Services-Final (2).mp4"; // Default video path
import img1 from "./up1.jpg"
import img2 from "./up2.jpg"
import img3 from "./up3.jpg"
import img4 from "./up5.jpg"

export default function Video({ data }) {

  const contentArray = data?.upcomming_events?.content
    ? (() => {
        try {
          return JSON.parse(data?.upcomming_events?.content); 
        } catch (e) {
          console.error("Failed to parse content:", e);
          return []; 
        }
      })()
    : [];

  return (
    <>
    <div className="videosec">
      {/* Section Title */}
      <h2>{data?.upcomming_events?.section_title || "Upcoming Events"}</h2>
      <div className="card">
        <div className="card-content">
          <Container>
            <Row className="align-items-center">
              {/* Text Content */}
              <Col md={6} sm={12} className="card-text">
                <h2>{data?.upcomming_events?.title || "AUSTRALIAN CYBER CONFERENCE"}</h2>
                
               
               
                    {/* Display each content item separately with <h4> */}
                   
                  <div>
             {contentArray?.map((item, index) => (
              <h4 key={index} className="event-detail">
             {item}
              </h4>
  ))}
</div>

                   
                 
               
                
                <div className="button-container">
                  <Link
                    to={data?.upcomming_events?.button_link || "/upcoming-events"}
                    className="cta-button"
                    onClick={() => {
                      setTimeout(() => {
                        const element = document.getElementById("upcoming-events");
                        if (element) {
                          element.scrollIntoView({ behavior: "smooth" });
                        }
                      }, 0);
                    }}
                  >
                    Know More
                  </Link>
                </div>
              </Col>
 {/* Video Content */}
 <Col md={12} sm={12} className="card-video">
                <video
                  className="responsive-video"
                  width="100%" // Makes the video responsive
                  height="315"
                  controls
                  autoPlay
                  loop
                  muted
                >
                  <source src={data?.upcomming_events?.video_file || video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>

    <div className="carousel-body">
  <div className="carousel">
    <div className="wrap">
      {Array.isArray(data?.upcomming_events?.event_images) && data.upcomming_events?.event_images.length > 0 ? (
        // Duplicating the images for seamless looping
        [...data.upcomming_events?.event_images, ...data.upcomming_events?.event_images].map((eventImage, index) => (
          <img
            key={`${eventImage.id || index}-${index}`}
            src={eventImage.image}
            alt={`Event ${index + 1}`}
            className="carousel-image"
            onError={(e) => (e.target.src = "./fallback-image.jpg")}
          />
        ))
      ) : (
        <p>No images available</p>
      )}
    </div>
  </div>
</div>


</>
  );
}
