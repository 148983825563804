import React, { useState } from 'react';
import "./Staffing-services.css";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import banner from '../../components/assests/Outsourcing/staff.jpg';
import Assesment from "../../pages/IdentityGovernance/assesment.png";
import Strategy  from "../../pages/IdentityGovernance/stratagy.png";
import Implementation from "../../pages/IdentityGovernance/implimentation.png";
import Training from "../../pages/IdentityGovernance/training.png";
import partner from "../../pages/IdentityGovernance/partner.jpg";
import Support from "../../pages/IdentityGovernance/customer-service.png";
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos'; // Import AOS
import  { useEffect } from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Recuirement from '../../components/assests/staffingservices/Recruitment.jpg';
import talent from '../../components/assests/staffingservices/TalentPool.jpg';
import hiring from '../../components/assests/staffingservices/HiringProcess.jpg';
import staffing from '../../components/assests/staffingservices/StaffingModels.jpg';
import  Consultation from '../../components/assests/staffingservices/Consultation.jpg';
import { Helmet } from 'react-helmet';
import Loader from "../../components/loader/loader";

const Staffingservices = () => {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [currentOffering, setCurrentOffering] = useState(0);
  const [offerings, setOfferings] = useState([]); // initialize as empty array

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}api/staffing-and-outsourcing`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        setData(result);
        const titles = result.staffing_services?.service_title || [];
        const descriptions = result.staffing_services?.service_content || [];
        const images = result.staffing_services?.service_image || [];
  
        const offerings = titles.map((title, index) => ({
          title: title || "No title available",
          description: descriptions[index] || "No description available.",
          image: images[index] || "default-image-url",
        }));
  
        setOfferings(offerings);
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setError(error.message); // Optionally set an error state
      } finally {
        setLoading(false); // Set loading to false once the data is fetched
      }
    };
  
    fetchData();
  }, []);
  
  useEffect(() => {
    AOS.init({ duration: 1000, once: true }); // Initialize AOS once
  }, []);
  
  const changeOffering = (direction) => {
    if (direction === 'next') {
      setCurrentOffering((prevOffering) => (prevOffering + 1) % offerings.length); // Loop to the start
    } else if (direction === 'prev') {
      setCurrentOffering((prevOffering) => (prevOffering - 1 + offerings.length) % offerings.length); // Loop to the end
    }
  };
 
  
  return (
    <>
    {loading ? (
      <Loader />
   ) : (
    <>
     <Helmet>
        <title> {data?.staffing_services?.meta_title || "staffing_services " } </title>
        <meta name="description" content= {data?.staffing_services?.meta_description || "staffing_services"  } />
        <meta
        name="keywords"
        content= {data?.staffing_services?.meta_keywords || "staffing_services " } 
      />
      </Helmet>
      <Header identityPage={true} />
  
      {/* Image and Overlay Section */}
      <div className="identity-image-container">
        <img
          src= {data?.staffing_services?.banner_image ||banner}
          alt="A depiction of the company's philosophy"
          className="identity-page-image"
        />
        <div className="overlay-text">
          <h1>Staffing Services</h1>
          <p>Home <i className="fa-solid fa-greater-than benner-icon"></i> Services <i className="fa-solid fa-greater-than benner-icon"></i> Staffing and Outsourcing Services <i className="fa-solid fa-greater-than benner-icon"></i> Staffing Services</p>
        </div>
      </div>
  
      {/* Staffing and Outsourcing Description */}
      <section className="staffing-outsourcing" id='staffing-services'>
        <div className="container">
          <div className="service-description" data-aos="zoom-in">
            <p>
            {data?.staffing_services?.content || " Securoak's Staffing and Outsourcing Services empower organizations to streamline operations and focus on core competencies. Our tailored staffing solutions connect you with skilled professionals, while our outsourcing services manage non-core functions, enhancing efficiency and reducing costs. Together, we drive innovation and optimize resources for sustainable growth and success. With our support, you can focus on your business's strategic goals, while we take care of the operational aspects, ensuring your business remains agile and competitive in a fast-paced market."} {/* Use 'data' here */}
           
              </p>
          </div>
        </div>
      </section>
  
      {/* Staffing Services */}
      <section className="staffing-services">
      <div className="staffing-services-container">
        <div className="section-title">
          <h2 className="Staffing-Services">
            {data?.staffing_services?.staffing_services_title || "Staffing Services"} {/* Use 'data' here */}
          </h2>
          <p className='staffing-content' data-aos="zoom-in">
            {data?.staffing_services?.staffing_services_content || "At Securoak, we understand that an organization's success hinges on its talent. Our Staffing Services are expertly crafted to connect you with skilled professionals who seamlessly integrate into your team, enhancing productivity from day one."}
          </p>
        </div>

          {offerings.length > 0 ? (
            <div className="offerings">
              <img
                src={offerings[currentOffering].image}
                alt={offerings[currentOffering].title}
                className="offering-image"
              />
  
              <div className="offering offering3" data-aos="zoom-in-up">
                <h5>Key Offerings</h5>
                <h3 className='staffing-sub-titles'>{offerings[currentOffering].title}</h3>
                <p className='staffing-sub-para'>{offerings[currentOffering].description}</p>
              </div>
            </div>
          ) : (
            <p></p> 
          )}
  
          <div className="navigation" data-aos="zoom-in">
            <button className="prev-btn prev-btn2" style={{ marginLeft: 0 }} onClick={() => changeOffering('prev')}>Prev</button>
            <button className="next-btn next-btn2" style={{ marginLeft: 0 }}onClick={() => changeOffering('next')}>Next</button>
          </div>
        </div>
  
        <div className="cta">
          <h3>
          {data?.staffing_services?.title_2 || "Unlock Your Potential with Securoak"}
            </h3>
          <p className='staffing-para'>
          {data?.staffing_services?.content_2 || "Partnering with Securoak unlocks your organization's full potential. Our dedicated approach to talent acquisition enables you to build high-performing teams that drive innovation and success. Allow us to navigate the complexities of staffing so you can concentrate on what you do best."}
          </p>
        </div>
      </section>
 
      <section className="unlocking-potential-section">
  <div className="container">
    <h2 className="section-title">{data?.staffing_services?.your_journey_main_title || "Your Journey with Us"}</h2>
    <p className="section-description">
      {data?.staffing_services?.your_journey_main_content || 
        "By choosing Securoak's WIAM solutions, you unlock a myriad of business benefits:"}
    </p>
    <div className="benefits-list">
      {data?.staffing_services?.your_journey_title?.map((title, index) => (
        <div 
          className="benefit-item" 
          data-aos="fade-up" 
          data-aos-delay={200 + index * 100} 
          key={index}
        >
          <div className="benefit-content">
            <img 
              src={data?.staffing_services?.logo?.[index] || "/default-image.png"} 
              alt={title} 
              className="benefit-image" 
            />
            <div className="benefit-text">
              <strong>{title}</strong><br />
              {data?.staffing_services?.your_journey_content?.[index] || "Default content."}
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
</section>




<section className="why-partner-with-us">
      <Row className="staff-container">
        {/* Left Side Content */}
        <Col md={12} sm={12} lg={6} className="content-left">
          <h2 data-aos="fade-down">
          {data?.staffing_services?.why_partner_title || "Why Partner with Us?."} 
            </h2>
          <p data-aos="fade-up">
          {data?.staffing_services?.why_partner_content || "Choosing Securoak means gaining a strategic partner committed to your success. With our deep industry knowledge, unwavering commitment to quality, and personalized service, you can trust that you have the right talent to meet your business objectives. Together, we can build a brighter future for your organization, all while embedding the necessary AI and cybersecurity capabilities to navigate today’s evolving landscape.."} 
          </p>
        </Col>
     
        {/* Right Side Image */}
        <Col md={12} sm={12} lg={6} className="content-right">
      
          <img src={data?.staffing_services?.why_partner_image || ""}  alt="Why Partner with Us" data-aos="fade-left"/>
          
        </Col>
      </Row>
    </section>



      <Footer />
    </>
  )
}
</>
);
}
export default Staffingservices;
