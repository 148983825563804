import React, { useState ,useEffect ,useRef} from "react";
import "./Contact.css";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import baanner from '../components/assests/conbanner.jpg';
import ReCAPTCHA from "react-google-recaptcha"; 
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Helmet } from 'react-helmet';
import DOMPurify from 'dompurify';
import conimg from '../components/assests/why2.jpg'
import Swal from 'sweetalert2';
import Select from "react-select";

const ContactUs = () => {
  const formRef = useRef();
  const [selectedLocation, setSelectedLocation] = useState(0);
  const [zoomedUrl, setZoomedUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}api/contact-us`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        setData(result[0]); // Update state with API response
      } catch (error) {
        // setError(error.message); 
      } finally {
        setLoading(false); // Stop loading indicator
      }
    };

    fetchData();
  }, []);

  // const locations = [
   
  //   {
  //     name: "Australia, Sydney",
  //     address: "Level 49, 8 Parramatta Square,\n 10 Darcy Street, \n Parramatta, NSW 2150, Australia",
  //      email:"contact@securoak.com",
  //     mapUrl:
  //       "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3314.8436741383366!2d150.99890605729453!3d-33.81634704732099!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12a34c63122e31%3A0x8b46ddd0ba20b670!2s6%20%26%208%20Parramatta%20Square!5e0!3m2!1sen!2sae!4v1683199017361!5m2!1sen!2sae",
  
  //     },
  //     {
  //       name: "India, Chennai",
  //       address: `Securoak Consulting LLP
  //   Office No: 334, Regus Shyamal Gardens,
  //   136, Arcot Road, 3rd Floor,
  //   Shyamala Towers, Saligramam,
  //   Chennai, Tamil Nadu - 600093`,
  //   email:"contact@securoak.com",
  //       mapUrl:
  //        "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3886.804254434122!2d80.1960446741216!3d13.048128313197774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2s!5e0!3m2!1sen!2sin!4v1731925719340!5m2!1sen!2sin",
      
  //       },
  // ];

  const addressdata = data?.address?.map((item) => ({
    id: item.id,          
    name: item.title || "Australia, Sydney", 
    address: item.address || "Level 49, 8 Parramatta Square,\n 10 Darcy Street, \n Parramatta, NSW 2150, Australia",  
    email: item.email || "contact@securoak.com",     
    mapUrl: item.map_link || "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3314.8436741383366!2d150.99890605729453!3d-33.81634704732099!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12a34c63122e31%3A0x8b46ddd0ba20b670!2s6%20%26%208%20Parramatta%20Square!5e0!3m2!1sen!2sae!4v1683199017361!5m2!1sen!2sae",
  })) || [];  // Default to an empty array if `data?.address` is undefined



  const handleLocationChange = (index) => {
    setSelectedLocation(index);
  };

  const [formData, setFormData] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    country: "",
  });
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name)
    console.log(value)
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [isSubmitting, setIsSubmitting] = useState(false); 
  const [isSubscribed, setIsSubscribed] = useState(false); 


  const [selectedCountry, setSelectedCountry] = useState(null);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption); // Update the selected country
    setFormData((prevData) => ({
      ...prevData,
      country: selectedOption.value, // Update formData with the selected country value
    }));
    console.log("Selected Country:", selectedOption);
  };

  

  const countryOptions = [
    { value: "Afghanistan", label: "Afghanistan" },
    { value: "Albania", label: "Albania" },
    { value: "Algeria", label: "Algeria" },
    { value: "Andorra", label: "Andorra" },
    { value: "Angola", label: "Angola" },
    { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
    { value: "Argentina", label: "Argentina" },
    { value: "Armenia", label: "Armenia" },
    { value: "Australia", label: "Australia" },
    { value: "Austria", label: "Austria" },
    { value: "Azerbaijan", label: "Azerbaijan" },
    { value: "Bahamas", label: "Bahamas" },
    { value: "Bahrain", label: "Bahrain" },
    { value: "Bangladesh", label: "Bangladesh" },
    { value: "Barbados", label: "Barbados" },
    { value: "Belarus", label: "Belarus" },
    { value: "Belgium", label: "Belgium" },
    { value: "Belize", label: "Belize" },
    { value: "Benin", label: "Benin" },
    { value: "Bhutan", label: "Bhutan" },
    { value: "Bolivia", label: "Bolivia" },
    { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
    { value: "Botswana", label: "Botswana" },
    { value: "Brazil", label: "Brazil" },
    { value: "Brunei", label: "Brunei" },
    { value: "Bulgaria", label: "Bulgaria" },
    { value: "Burkina Faso", label: "Burkina Faso" },
    { value: "Burundi", label: "Burundi" },
    { value: "Cabo Verde", label: "Cabo Verde" },
    { value: "Cambodia", label: "Cambodia" },
    { value: "Cameroon", label: "Cameroon" },
    { value: "Canada", label: "Canada" },
    { value: "Central African Republic", label: "Central African Republic" },
    { value: "Chad", label: "Chad" },
    { value: "Chile", label: "Chile" },
    { value: "China", label: "China" },
    { value: "Colombia", label: "Colombia" },
    { value: "Comoros", label: "Comoros" },
    { value: "Congo (Congo-Brazzaville)", label: "Congo (Congo-Brazzaville)" },
    { value: "Costa Rica", label: "Costa Rica" },
    { value: "Croatia", label: "Croatia" },
    { value: "Cuba", label: "Cuba" },
    { value: "Cyprus", label: "Cyprus" },
    { value: "Czechia (Czech Republic)", label: "Czechia (Czech Republic)" },
    { value: "Denmark", label: "Denmark" },
    { value: "Djibouti", label: "Djibouti" },
    { value: "Dominica", label: "Dominica" },
    { value: "Dominican Republic", label: "Dominican Republic" },
    { value: "Ecuador", label: "Ecuador" },
    { value: "Egypt", label: "Egypt" },
    { value: "El Salvador", label: "El Salvador" },
    { value: "Equatorial Guinea", label: "Equatorial Guinea" },
    { value: "Eritrea", label: "Eritrea" },
    { value: "Estonia", label: "Estonia" },
    { value: "Eswatini (fmr. Swaziland)", label: "Eswatini (fmr. Swaziland)" },
    { value: "Ethiopia", label: "Ethiopia" },
    { value: "Fiji", label: "Fiji" },
    { value: "Finland", label: "Finland" },
    { value: "France", label: "France" },
    { value: "Gabon", label: "Gabon" },
    { value: "Gambia", label: "Gambia" },
    { value: "Georgia", label: "Georgia" },
    { value: "Germany", label: "Germany" },
    { value: "Ghana", label: "Ghana" },
    { value: "Greece", label: "Greece" },
    { value: "Grenada", label: "Grenada" },
    { value: "Guatemala", label: "Guatemala" },
    { value: "Guinea", label: "Guinea" },
    { value: "Guinea-Bissau", label: "Guinea-Bissau" },
    { value: "Guyana", label: "Guyana" },
    { value: "Haiti", label: "Haiti" },
    { value: "Holy See", label: "Holy See" },
    { value: "Honduras", label: "Honduras" },
    { value: "Hungary", label: "Hungary" },
    { value: "Iceland", label: "Iceland" },
    { value: "India", label: "India" },
    { value: "Indonesia", label: "Indonesia" },
    { value: "Iran", label: "Iran" },
    { value: "Iraq", label: "Iraq" },
    { value: "Ireland", label: "Ireland" },
    { value: "Israel", label: "Israel" },
    { value: "Italy", label: "Italy" },
    { value: "Jamaica", label: "Jamaica" },
    { value: "Japan", label: "Japan" },
    { value: "Jordan", label: "Jordan" },
    { value: "Kazakhstan", label: "Kazakhstan" },
    { value: "Kenya", label: "Kenya" },
    { value: "Kiribati", label: "Kiribati" },
    { value: "Korea (North)", label: "Korea (North)" },
    { value: "Korea (South)", label: "Korea (South)" },
    { value: "Kosovo", label: "Kosovo" },
    { value: "Kuwait", label: "Kuwait" },
    { value: "Kyrgyzstan", label: "Kyrgyzstan" },
    { value: "Laos", label: "Laos" },
    { value: "Latvia", label: "Latvia" },
    { value: "Lebanon", label: "Lebanon" },
    { value: "Lesotho", label: "Lesotho" },
    { value: "Liberia", label: "Liberia" },
    { value: "Libya", label: "Libya" },
    { value: "Liechtenstein", label: "Liechtenstein" },
    { value: "Lithuania", label: "Lithuania" },
    { value: "Luxembourg", label: "Luxembourg" },
    { value: "Madagascar", label: "Madagascar" },
    { value: "Malawi", label: "Malawi" },
    { value: "Malaysia", label: "Malaysia" },
    { value: "Maldives", label: "Maldives" },
    { value: "Mali", label: "Mali" },
    { value: "Malta", label: "Malta" },
    { value: "Marshall Islands", label: "Marshall Islands" },
    { value: "Mauritania", label: "Mauritania" },
    { value: "Mauritius", label: "Mauritius" },
    { value: "Mexico", label: "Mexico" },
    { value: "Micronesia", label: "Micronesia" },
    { value: "Moldova", label: "Moldova" },
    { value: "Monaco", label: "Monaco" },
    { value: "Mongolia", label: "Mongolia" },
    { value: "Montenegro", label: "Montenegro" },
    { value: "Morocco", label: "Morocco" },
    { value: "Mozambique", label: "Mozambique" },
    { value: "Myanmar (formerly Burma)", label: "Myanmar (formerly Burma)" },
    { value: "Namibia", label: "Namibia" },
    { value: "Nauru", label: "Nauru" },
    { value: "Nepal", label: "Nepal" },
    { value: "Netherlands", label: "Netherlands" },
    { value: "New Zealand", label: "New Zealand" },
    { value: "Nicaragua", label: "Nicaragua" },
    { value: "Niger", label: "Niger" },
    { value: "Nigeria", label: "Nigeria" },
    { value: "North Macedonia (Macedonia)", label: "North Macedonia (Macedonia)" },
    { value: "Norway", label: "Norway" },
    { value: "Oman", label: "Oman" },
    { value: "Pakistan", label: "Pakistan" },
    { value: "Palau", label: "Palau" },
    { value: "Palestine State", label: "Palestine State" },
    { value: "Panama", label: "Panama" },
    { value: "Papua New Guinea", label: "Papua New Guinea" },
    { value: "Paraguay", label: "Paraguay" },
    { value: "Peru", label: "Peru" },
    { value: "Philippines", label: "Philippines" },
    { value: "Poland", label: "Poland" },
    { value: "Portugal", label: "Portugal" },
    { value: "Qatar", label: "Qatar" },
    { value: "Romania", label: "Romania" },
    { value: "Russia", label: "Russia" },
    { value: "Rwanda", label: "Rwanda" },
    { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
    { value: "Saint Lucia", label: "Saint Lucia" },
    { value: "Saint Vincent and the Grenadines", label: "Saint Vincent and the Grenadines" },
    { value: "Samoa", label: "Samoa" },
    { value: "San Marino", label: "San Marino" },
    { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
    { value: "Saudi Arabia", label: "Saudi Arabia" },
    { value: "Senegal", label: "Senegal" },
    { value: "Serbia", label: "Serbia" },
    { value: "Seychelles", label: "Seychelles" },
    { value: "Sierra Leone", label: "Sierra Leone" },
    { value: "Singapore", label: "Singapore" },
    { value: "Slovakia", label: "Slovakia" },
    { value: "Slovenia", label: "Slovenia" },
    { value: "Solomon Islands", label: "Solomon Islands" },
    { value: "Somalia", label: "Somalia" },
    { value: "South Africa", label: "South Africa" },
    { value: "South Sudan", label: "South Sudan" },
    { value: "Spain", label: "Spain" },
    { value: "Sri Lanka", label: "Sri Lanka" },
    { value: "Sudan", label: "Sudan" },
    { value: "Suriname", label: "Suriname" },
    { value: "Sweden", label: "Sweden" },
    { value: "Switzerland", label: "Switzerland" },
    { value: "Syria", label: "Syria" },
    { value: "Tajikistan", label: "Tajikistan" },
    { value: "Tanzania", label: "Tanzania" },
    { value: "Thailand", label: "Thailand" },
    { value: "Timor-Leste", label: "Timor-Leste" },
    { value: "Togo", label: "Togo" },
    { value: "Tonga", label: "Tonga" },
    { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
    { value: "Tunisia", label: "Tunisia" },
    { value: "Turkey", label: "Turkey" },
    { value: "Turkmenistan", label: "Turkmenistan" },
    { value: "Tuvalu", label: "Tuvalu" },
    { value: "Uganda", label: "Uganda" },
    { value: "Ukraine", label: "Ukraine" },
    { value: "United Arab Emirates", label: "United Arab Emirates" },
    { value: "United Kingdom", label: "United Kingdom" },
    { value: "United States of America", label: "United States of America" },
    { value: "Uruguay", label: "Uruguay" },
    { value: "Uzbekistan", label: "Uzbekistan" },
    { value: "Vanuatu", label: "Vanuatu" },
    { value: "Vatican City", label: "Vatican City" },
    { value: "Venezuela", label: "Venezuela" },
    { value: "Vietnam", label: "Vietnam" },
    { value: "Yemen", label: "Yemen" },
    { value: "Zambia", label: "Zambia" },
    { value: "Zimbabwe", label: "Zimbabwe" },
  ];
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(JSON.stringify(formData));
      const response = await fetch(process.env.REACT_APP_API + 'api/contact-form', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
  
      // Check if the response is successful and if it's a JSON response
      if (response.ok) {
        const data = await response.json(); // Only parse JSON if the response is OK
  
        console.log("Response Data:", data);
  
        if (data.success) {
          Swal.fire("Success!", "Your form has been submitted!", "success");
  
          // Reset form data after successful submission
          formRef.current.reset();
        } else {
          let errorMessages = 'Something went wrong. Please try again.';  // Default error message
          
          if (data.errors) {
            // If `data.errors` is an array, map over it and join with <br>
            if (Array.isArray(data.errors)) {
              errorMessages = data.errors.join('<br>');
            } else if (typeof data.errors === 'object') {
              // If `data.errors` is an object, extract the error messages
              errorMessages = Object.values(data.errors).join('<br>');
            } else {
              // If `data.errors` is some other type, assume it's a generic message
              errorMessages = data.errors;
            }
          }
        
          // Show error messages in SweetAlert
          Swal.fire({
            title: "Error!",
            html: errorMessages, // Use HTML to support line breaks
            icon: "error",
          });
        }
        
        
      } else {
        // If response status is not OK, show a general error
        Swal.fire("Error!", "Failed to submit the form.", "error");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      // In case of a network error or other issue, show a generic error message
      Swal.fire("Error!", "Failed to submit the form. Please try again later.", "error");
      
    }
  };
  

  return (
    <>
    <Helmet>
  <title>{data?.meta_title || "Contact Us"}</title>
  <meta
    name="description"
    content={data?.meta_description || "Contact Us"}
  />
  <meta
    name="keywords"
    content={
      data?.meta_keywords ||
      "Contact Us"
    }
  />
</Helmet>
         <div className="contact-header" id="contact">
        <Header identityPage={true} />
      </div>

      <div className="identity-image-container">
        <img
          src={baanner}
          alt="A depiction of the company's philosophy"
          className="identity-page-image"
        />
        <div className="overlay-text">
          <h1>{data?.title1 || " Contact us for Securoak Services & Explore Securoak Solutions"}</h1>
        
          <p>
            Home <i className="fa-solid fa-greater-than banner-icon"></i> Contact Us
          </p>
        </div>
      </div>



 <div className="contact-container">
      <Row className="contact-row">
        {/* Left Column - Form */}
        <Col xs={12} md={12} sm={12} lg={6}>
          <form  ref={formRef} className="contact-left" onSubmit={handleSubmit}>
            <div className="contact-left-title">
              <h2>Get in touch</h2>
              <hr />
            </div>
            <div className="name-fields" style={{ width: "100%" }}>
              <input
                type="text"
                name="firstName"
                placeholder="First Name*"
                className="contact-inputs mandatory"
                required
                
                onChange={handleInputChange}              />
              <input
                type="text"
                name="lastName"
                placeholder="Surname*"
                className="contact-inputs mandatory"
                required
               
                onChange={handleInputChange}
              />
            </div>
            <input
              type="email"
              name="email"
              placeholder="Your Email*"
              className="contact-inputs mandatory"
              required
              
              onChange={handleInputChange}
            />
            <div style={{ width: "100%" }}>
      <Select
        options={countryOptions}
        value={selectedCountry}
        name="country"
        onChange={handleCountryChange}
        placeholder="Select Country*"
        isSearchable
      />
    </div>
            <label>
              <input
                type="checkbox"
                required
                className="mandatory checkbox"
                checked={isSubscribed}
                onChange={(e) => setIsSubscribed(e.target.checked)}
              />
              I agree to be emailed *
            </label>
            <button
              className="contact-button"
              type="submit"
              disabled={!isSubscribed || isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Subscribe"}
            </button>
            <p className="note">
              Please note, some email providers will automatically direct emails from Securoak into your spam/junk folder.
            </p>
          </form>
        </Col>

        {/* Right Column - Information */}
        <Col  xs={12} md={12} sm={12} lg={6}>
          <div className="contact-right">
            <div className="contact-right-title">
              <h2>
                {data?.content_title || "Stay Informed with Securoak"}</h2>
                <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              data?.content || "Choose Securoak to empower your organization with a resilient IT infrastructure, comprehensive security posture, and the advanced capabilities of AI to thrive in a rapidly evolving digital landscape."
            ),
          }}
        />
       
       
            </div>
           
          </div>
        </Col>
      </Row>
    </div>


    <div className="map-container">
  <div className="location-buttons">
    {addressdata.map((location, index) => (
      <button
        key={index}
        className={`location-btn ${selectedLocation === index ? "active" : ""}`}
        onClick={() => handleLocationChange(index)}
      >
        {location.name}
      </button>
    ))}
  </div>

  <Row className="location-content">
    {/* Left: Map */}
    <Col md={12} sm={12} lg={7} className="map-column">
      {addressdata[selectedLocation] && addressdata[selectedLocation].mapUrl ? (
        <iframe
          src={addressdata[selectedLocation].mapUrl}
          width="100%"
          height="350"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Map"
        ></iframe>
      ) : (
        <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3314.8436741383366!2d150.99890605729453!3d-33.81634704732099!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12a34c63122e31%3A0x8b46ddd0ba20b670!2s6%20%26%208%20Parramatta%20Square!5e0!3m2!1sen!2sae!4v1683199017361!5m2!1sen!2sae"
      width="100%"
      height="350"
      style={{ border: 0 }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
      title="Google Map"
    ></iframe>
      )}
    </Col>

    {/* Right: Address */}
    <Col md={12} sm={12} lg={4} className="address-column">
    <h3 className="location-title">
  {addressdata[selectedLocation]?.name || "Location updated Soon"}
</h3>

      <div className="location-details">
      <p
  className="location-address"
  dangerouslySetInnerHTML={{
    __html: DOMPurify.sanitize(
      // Parse the address string, join with line breaks, or use a default address if it's not available
      addressdata[selectedLocation]?.address
        ? JSON.parse(addressdata[selectedLocation]?.address).join('<br />')
        : 'Level 49, 8 Parramatta Square,<br />10 Darcy Street,<br />Parramatta, NSW 2150, Australia'
    )
  }}
></p>

      
<div className="email-container">
  <i className="fa-regular fa-envelope email-icon"></i>
  <h4 className="location-email">
    {addressdata[selectedLocation]?.email || "email will be update soon"}
  </h4>
</div>

      </div>
    </Col>
  </Row>
</div>

      <Footer />
    </>
  );
}

export default ContactUs;
