import React from 'react'
import {useState , useEffect } from 'react';
import './Privileged-Access-Management.css'
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import banner from '../WorkforceIdentity/workforce2.jpg';

import Assesment from "../../components/assests/priviledge/assesment.png";
import Strategy  from "../../components/assests/priviledge/stratagey.png";
import Implementation from "../../components/assests/priviledge/implimentation.png";
import Training from "../../components/assests/priviledge/training.png";
import why from "../../components/assests/priviledge/security2.jpg";
import why2 from "../../components/assests/priviledge/security3.jpg";
import img1 from './img1.jpg'
import img2 from './img2.jpg'
import img3 from './img3.jpg'
import banner1 from './privilage.jpeg'
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos'; // Import AOS

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Support from "../../components/assests/priviledge/customer-service.png";
import { Card, ListGroup } from 'react-bootstrap'; // Assuming you're using Bootstrap for card and list styling
import DOMPurify from 'dompurify';
import { Helmet } from 'react-helmet'
import Loader from "../../components/loader/loader";

const PrivilegedAccessManagement = () => {

  const [itemsToDisplay, setItemsToDisplay] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}api/identify-offerings`);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const result = await response.json();
        console.log("API Response:", result); // Log to verify structure
        setData(result);
      } catch (err) {
        console.error("API Fetch Error:", err.message);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    AOS.init({ duration: 1000, once: true });
  }, []);
  useEffect(() => {
    if (data) {
      // Dynamically create items based on the available data keys
      const items = Object.keys(data?.privilege_access_management || {})
        .filter((key) => key.startsWith("title_")) // Filter out keys starting with 'title_'
        .map((key, index) => {
          const i = index + 1; // Assuming your titles start from title_1, content_1, image_1
          const title = data?.privilege_access_management?.[`title_${i}`];
          const text = data?.privilege_access_management?.[`content_${i}`];
          const image = data?.privilege_access_management?.[`image_${i}`];

          if (!title || !text || !image) {
            console.warn(`Missing data for item ${i}:`, { title, text, image });
          }

          return {
            title: title || `Default Title ${i}`,
            text: text || `Default Content ${i}`,
            image: image || "/default-image.jpg",
          };
        });

      console.log("Mapped Items After Fallback:", items);
      setItemsToDisplay(items);
    }
  }, [data]);


  
    const potentialItems = [
        {
          image:img1, title:"Customized Access Control", text: " Utilizing AI-driven insights, we design access control measures tailored to your organization’s specific needs. This ensures that only authorized personnel can access critical systems, significantly reducing the risk of both internal and external threats."
        },
        {
          image:img2, title:"Efficient Credential Management",text: " Our AI-enhanced solutions streamline the management of privileged credentials, reducing administrative overhead while bolstering security. By automating key processes such as password rotation and access provisioning, we free your IT teams to concentrate on strategic initiatives rather than routine tasks."
        },
        {
          image:img3, title:"Proactive Threat Detection", text: " Continuous monitoring of privileged accounts through AI algorithms enables us to identify suspicious activities in real-time. This proactive approach allows for swift responses to potential security threats, enhancing your organization’s ability to mitigate risks before they escalate."
        },
        {
          image:img1, title:"Enhanced Compliance Support",text: " Our PAM solutions simplify compliance with regulatory requirements by implementing robust controls and providing detailed audit trails for privileged access activities. AI analytics facilitate efficient reporting and documentation necessary for meeting industry standards, thereby reducing your compliance burden."
        },
        {
          image:img2, title:"Seamless Integration", text: " Our PAM solutions are designed for effortless integration with your existing systems, providing a cohesive framework for managing privileged access without disruption. This ensures that security enhancements are smoothly implemented, preserving operational efficiency and user experience."
        },
       
       
      ];
      // const potentialItems2 = [
      //   {
      //       text: "Enhanced Compliance Support: Our PAM solutions simplify compliance with regulatory requirements by implementing robust controls and providing detailed audit trails for privileged access activities. AI analytics facilitate efficient reporting and documentation necessary for meeting industry standards, thereby reducing your compliance burden."
      //     },
      //     {
      //       text: "Seamless Integration: Our PAM solutions are designed for effortless integration with your existing systems, providing a cohesive framework for managing privileged access without disruption. This ensures that security enhancements are smoothly implemented, preserving operational efficiency and user experience."
      //     },
      // ];
      
      useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs';
        script.type = 'module';
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script);
        };
    
        
      }, []);
  return (
    <>
    {loading ? (
      <Loader />
   ) : (
    <>
    <Helmet>
        <title> {data?.privilege_access_management?.meta_title || "privilege_access_management " } </title>
        <meta name="description" content= {data?.privilege_access_management?.meta_description || "privilege_access_management"  } />
        <meta
        name="keywords"
        content= {data?.privilege_access_management?.meta_keywords || "privilege_access_management " } 
      />
      </Helmet>
     <Header identityPage={true} />
    <div>
       {/* Image and Overlay Section */}
       <div className="identity-image-container">
        <img
          src={data?.privilege_access_management?.banner_image || banner1}
          alt="A depiction of the company's philosophy"
          className="identity-page-image"
        />
        <div className="overlay-text">
          <h1>Privileged Access Management </h1>
          <p>Home <i className="fa-solid fa-greater-than benner-icon"></i> Privileged Access Management</p>
        </div>
      </div>

    </div>


    <div className="pam-section" data-aos="fade-up">
  <p className="pam-intro">
  <div
  dangerouslySetInnerHTML={{
    __html: DOMPurify.sanitize(
      (data?.privilege_access_management?.content || "").replace(
        'text-big',
        'pam-subtitle aos-init aos-animate'
      )
    ),
  }}
  
        />
  </p>
  
  
  
  {/* <h4 className="pam-subtitle" data-aos="fade-up">Expertise in Navigating Cybersecurity Complexities</h4>
  
  <p className="pam-expertise privilage-para" data-aos="fade-up">
    With extensive experience in delivering PAM solutions, Securoak is uniquely equipped to handle the complexities of credential management, compliance, and data breach prevention. Our expert team collaborates with your organization to develop a <strong>tailored PAM strategy</strong> aligned with your unique security requirements, ensuring the protection of your critical assets against emerging cyber threats.
  </p> */}
</div>







<section className="journey-with-securoak" data-aos="fade-up">
      <h2 className="text-center journey-title">
        {data?.privilege_access_management?.journey_title || "Unlocking Your Potential"}
      </h2>
      {/* <p className="title-below-para">
        {data?.identity_governance_with_securoaks?.journey_content || "Default content."}
      </p> */}
      <div className="container pb-4">
        {itemsToDisplay.length > 0 ? (
          itemsToDisplay.map((item, index) => (
            <div
              key={index}
              className={`card-main d-flex mt-4 ${index % 2 === 0 ? "flex-row-reverse" : "flex-row"}`}
            >
              <div className="content flex-grow-1 d-flex flex-column align-items-center justify-content-start ">
                <h4 className=''>{item.title || "Streamlined Risk Management"}</h4>
                <div className="list-content">
                  <span>{item.text || "With automated identification of policy violations, our IG solutions enable you to address risks before they escalate. Our real-time detection capabilities, powered by machine learning, monitor access patterns and user activities around the clock. This allows you to prevent unauthorized access and compliance issues, fortifying your systems and safeguarding sensitive information from potential breaches."}</span>
                </div>
              </div>
              <div className="card-image d-flex flex-column align-items-center justify-content-start">
                <img
                  src={item.image}
                  className="unlock-img"
                  alt={item.title}
                  onError={(e) => (e.target.src = "./j1.jpg")}
                />
              </div>
            </div>
          ))
        ) : (
          <div></div>
        )}
      </div>
    </section>

{/* <section className="journey-with-securoak">
    <div className='text-center'>
  <h2 className="c journey-title">Unlocking Your Potential</h2>
  <p className='title-below-para'>Partnering with Securoak for your WIAM journey means embracing a structured approach to identity management that unlocks your organization’s full potential.</p>
</div>

      <Card className="mb-4 journey-card">
        <Card.Body className="d-flex flex-row">
          <div className="content-column">
            <ListGroup variant="flush">
              {potentialItems.map((item, index) => (
                <ListGroup.Item key={index} className="list-item">
                  <div className="list-icon">{item.icon}</div>
                  <span>{item.text}</span>
                </ListGroup.Item>
              ))}
            </ListGroup>
           
          </div>

          <div className="image-column">
          <dotlottie-player 
    src="https://lottie.host/b6f681e0-7777-4f16-83f5-b6f2bb35c65a/ExvDWUEra1.json"
    background="transparent"
    speed="1"
    className="potential-lottie"
    loop
    autoplay
  ></dotlottie-player>
          </div>
        </Card.Body>
      </Card>
    </section> */}




    {/* Unlocking Your Potential section */}

    <section className="unlocking-potential-section">
  <div className="container">
    <h2 className="section-title">{data?.privilege_access_management?.key_feature_main_title || "Your Journey with Securoak"}</h2>
    <p className="section-description">
      {data?.identity_governance_with_securoaks?.key_feature_main_content || 
        "By choosing Securoak's WIAM solutions, you unlock a myriad of business benefits:"}
    </p>
    <div className="benefits-list">
      {data?.privilege_access_management?.your_journey_title?.map((title, index) => (
        <div 
          className="benefit-item" 
          data-aos="fade-up" 
          data-aos-delay={200 + index * 100} 
          key={index}
        >
          <div className="benefit-content">
            <img 
              src={data?.privilege_access_management?.logo?.[index] || Assesment} 
              alt={title} 
              className="benefit-image" 
            />
            <div className="benefit-text">
              <strong>{title || "Strategy Development:"}</strong><br />
              {data?.privilege_access_management?.your_journey_content?.[index] || "We initiate the process with a thorough evaluation of your current PAM landscape, identifying risks and outlining a customized strategy that aligns with your organizational goals. Our assessment leverages AI tools to uncover hidden vulnerabilities and potential areas for improvement."}
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
</section>

<section className="privilage-partner-with-securoak" data-aos="fade-up">
  <div className="container">
    <h2 className="privilage-partner-with-securoak-title text-center mb-4">
      {data?.privilege_access_management?.why_partner_title || " Why Partner with Securoak?"}
     
    </h2>

    {/* Row for Content */}
    <Row className="align-items-center">
      {/* Left Column: Image */}
      <Col md={12} sm={12} lg={6} className="img-col text-center mb-4 mb-md-0">
        <img
          src={ data?.privilege_access_management?.why_choose_image || why }
          alt="Why Partner with Securoak"
          className="section-image img-fluid"
        />
      </Col>

      {/* Right Column: Text */}
      <Col md={12} sm={12} lg={6} className="text-col">
        <div className="text-wrapper">
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              data?.privilege_access_management?.why_partner_content|| "<p style=\"text-align:justify;\">Choosing <strong>Securoak</strong> for your <strong>Privileged Access Management</strong> needs means partnering with a trusted expert dedicated to fortifying your organization’s security posture in a rapidly changing cyber landscape. Our commitment to tailored solutions, innovative AI technologies, and continuous support empowers you to manage privileged access with confidence. Together, we will safeguard your critical assets, mitigate risks associated with privileged credentials, and enable your organization to thrive securely in a digital-first world.</p><p style=\"text-align:justify;\">By leveraging our advanced PAM solutions enhanced by AI, you can proactively address the complexities of cybersecurity, enhance compliance, and cultivate a culture of security that extends throughout your organization. This not only drives success but also positions your business to navigate the complexities of modern cyber threats with agility and resilience.</p>"
            ),
          }}
        />
        </div>
      </Col>
    </Row>
  </div>
</section>





    <Footer />
    </>
  )
}
</>
);
}
export default PrivilegedAccessManagement
