import React, { useState ,useEffect } from 'react';
import './JourneyWithSecuroak.css';
import img1 from './img1.jpg'
import img2 from './img2.jpg'
import img3 from './img3.jpg'
import assesment from "./j1.jpg"
// import { FaUsers, FaLock, FaCogs, FaChartLine, FaHandshake } from 'react-icons/fa';
import { Card, ListGroup } from 'react-bootstrap'; // Assuming you're using Bootstrap for card and list styling
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const potentialItems = [
  { image:img1, title:"Initial Consultation", text: " We engage with your team to understand your business goals, challenges, and current identity management systems, ensuring our solutions align with your vision." },
  { image:img2,title:"Needs Assessment", text: " Our consultants conduct a comprehensive evaluation of your existing processes, identifying gaps and opportunities for improvement in your identity management strategy." },
  { image:img3,title:"Customized Strategy Development", text: "Based on the assessment, we craft a tailored CIAM strategy that addresses your specific needs while adhering to regulatory standards and industry best practices." },
  { image:img1,title:"Training and Support", text: "We provide extensive training for your team on managing the new CIAM system, alongside ongoing support to address any queries or challenges." },
  { image:img2,title:"Continuous Monitoring and Optimization", text: " After deployment, we continuously monitor system performance and user engagement, providing regular insights and recommendations to enhance security and user experience further." },
];

const JourneyWithSecuroak = () => {


  
  const [itemsToDisplay, setItemsToDisplay] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}api/identify-offerings`);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const result = await response.json();
        console.log("API Response:", result); // Log to verify structure
        setData(result);
      } catch (err) {
        console.error("API Fetch Error:", err.message);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
   
  }, []);

  useEffect(() => {
    if (data) {
      // Dynamically create items based on the available data keys
      const items = Object.keys(data?.customer_identity_access_management || {})
        .filter((key) => key.startsWith("title_")) // Filter out keys starting with 'title_'
        .map((key, index) => {
          const i = index + 1; // Assuming your titles start from title_1, content_1, image_1
          const title = data?.customer_identity_access_management?.[`title_${i}`];
          const text = data?.customer_identity_access_management?.[`content_${i}`];
          const image = data?.customer_identity_access_management?.[`image_${i}`];

          if (!title || !text || !image) {
            console.warn(`Missing data for item ${i}:`, { title, text, image });
          }

          return {
            title: title || `Default Title ${i}`,
            text: text || `Default Content ${i}`,
            image: image || "/default-image.jpg",
          };
        });

      console.log("Mapped Items After Fallback:", items);
      setItemsToDisplay(items);
    }
  }, [data]);

 

  return (
    <section className="journey-with-securoak" data-aos="fade-up">
      <h2 className="text-center journey-title pb-4">
        {data?.customer_identity_access_management?.journey_title ||
          "Journey With Securoak"}
      </h2>
      {/* <p className="title-below-para">
        {data?.customer_identity_access_management?.journey_content || "Partnering with Securoak for your WIAM journey means embracing a structured approach to identity management that unlocks your organization’s full potential."}
      </p> */}
      <div className="container pb-5">
        {itemsToDisplay.length > 0 ? (
          itemsToDisplay.map((item, index) => (
           
              <div
                key={index}
                className={`card-main d-flex mb-1 ${
                  index % 2 === 0 ? "flex-row-reverse" : "flex-row"
                }`}
              >
                <div className="content flex-grow-1 d-flex flex-column align-items-center justify-content-start p-4">
                  <h4>{item.title || "Assessment"}</h4>
                  <div className="list-content">
                    <span>
                      {item.text ||
                        "We start with a comprehensive assessment of your current identity management systems, identifying gaps and opportunities for improvement. Our AI tools analyze existing workflows and highlight areas where security can be enhanced"}
                    </span>
                  </div>
                </div>
                <div className="card-image d-flex flex-column align-items-center justify-content-start">
                  <img
                    src={item.image ? item.image : assesment}
                    className="unlock-img"
                    alt={item.title}
                    onError={(e) => (e.target.src = "./j1.jpg")}
                  />
                </div>
              </div>
          
          ))
        ) : (
          <div>No items to display</div>
        )}
      </div>
    </section>

    //   <section className="journey-with-securoak">
    //     <h2 className="text-center journey-title">Journey With Securoak</h2>

    //     <Card className="mb-4 journey-card">
    //       <Card.Body className="d-flex flex-row">
    //         <div className="content-column">
    //           <ListGroup variant="flush">
    //             {potentialItems.map((item, index) => (
    //               <ListGroup.Item key={index} className="list-item">
    //                 <div className="list-icon">{item.icon}</div>
    //                 <span>{item.text}</span>
    //               </ListGroup.Item>
    //             ))}
    //           </ListGroup>
    //         </div>

    //         <div className="image-column">
    //         <dotlottie-player
    //   src="https://lottie.host/b6f681e0-7777-4f16-83f5-b6f2bb35c65a/ExvDWUEra1.json"
    //   background="transparent"
    //   speed="1"
    //   className="potential-lottie"
    //   loop
    //   autoplay
    // ></dotlottie-player>
    //         </div>
    //       </Card.Body>
    //     </Card>
    //   </section>
  );
};

export default JourneyWithSecuroak;
