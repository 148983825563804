import React from "react";
import "./WhyWeStandOut.scss"; // SCSS styling for the component
// import { FaShieldAlt, FaUsers, FaSyncAlt, FaLock } from 'react-icons/fa';
import * as FaIcons from "react-icons/fa";

import "aos/dist/aos.css"; // Import AOS styles
import AOS from "aos"; // Import AOS
import { useEffect } from "react";
import DOMPurify from "dompurify";

const WhyWeStandOut = ({ data }) => {
    useEffect(() => {
        AOS.init({ duration: 1000 }); // Initialize AOS
    }, []);

    return (
        <section className="why-we-stand-out">
            <h2>{data?.Home_services?.title || "Why We Stand Out"}</h2>
            <p className="intro">
                {/* If content is available, sanitize and render it, otherwise render fallback text */}
                {data?.Home_services?.content ? (
                    <span className="intro"
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                                data?.Home_services?.content
                            ),
                        }}
                    />
                ) : (
                    "Securoak distinguishes itself through AI-powered cybersecurity and robust IT solutions, specializing in secure identity management, seamless application integration, and resilient infrastructure. Backed by expert teams and strategic partnerships, we prioritize client-centered innovation, operational efficiency, and proactive risk management."
                )}
            </p>
            <div className="features-grid">
                <div className="feature" data-aos="fade-up">
                    {/* Conditionally render icon based on the 'card_1_icon' data */}
                    {data?.Home_services?.card_1_icon ? (
                        React.createElement(
                            FaIcons[data?.Home_services?.card_1_icon] ||
                                FaIcons.FaLock,
                            { className: "icon" }
                        )
                    ) : (
                        <FaIcons.FaLock className="icon" />
                    )}
                    <h3>
                        {data?.Home_services?.card_1_title ||
                            "Identity Management"}
                    </h3>
                    <p>
                        {data?.Home_services?.card_1_content ||
                            "Comprehensive identity and access management to enhance security and control."}
                    </p>
                </div>
                <div className="feature" data-aos="fade-up">
                    {/* Conditionally render icon based on the 'card_1_icon' data */}
                    {data?.Home_services?.card_2_icon ? (
                        React.createElement(
                            FaIcons[data?.Home_services?.card_2_icon] ||
                                FaIcons.FaShieldAlt,
                            { className: "icon" }
                        )
                    ) : (
                        <FaIcons.FaShieldAlt className="icon" />
                    )}

                    <h3>
                        {data?.Home_services?.card_2_title ||
                            "AI-Powered Cybersecurity"}
                    </h3>
                    <p>
                        {data?.Home_services?.card_2_content ||
                            "Advanced AI solutions to detect, prevent, and respond to security threats in real-time."}
                    </p>
                </div>

                <div className="feature" data-aos="fade-up">
                    {/* Conditionally render icon based on the 'card_1_icon' data */}
                    {data?.Home_services?.card_3_icon ? (
                        React.createElement(
                            FaIcons[data?.Home_services?.card_3_icon] ||
                                FaIcons.FaUsers,
                            { className: "icon" }
                        )
                    ) : (
                        <FaIcons.FaUsers className="icon" />
                    )}

                    <h3>
                        {data?.Home_services?.card_3_title ||
                            "Expert Team Support"}
                    </h3>
                    <p>
                        {data?.Home_services?.card_3_content ||
                            "Highly skilled teams providing strategic advice and hands-on support for secure operations."}
                    </p>
                </div>

                <div className="feature" data-aos="fade-up">
                    {/* Conditionally render icon based on the 'card_1_icon' data */}
                    {data?.Home_services?.card_4_icon ? (
                        React.createElement(
                            FaIcons[data?.Home_services?.card_4_icon] ||
                                FaIcons.FaSyncAlt,
                            { className: "icon" }
                        )
                    ) : (
                        <FaIcons.FaSyncAlt className="icon" />
                    )}

                    <h3>
                        {data?.Home_services.card_4_title ||
                            "Seamless Integration"}
                    </h3>
                    <p>
                        {data?.Home_services.card_4_content ||
                            "Effortless integration with existing systems to ensure smooth and continuous functionality."}
                    </p>
                </div>
            </div>
        </section>
    );
};

export default WhyWeStandOut;
