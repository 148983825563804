import React from "react";
import "../Partnership/partner.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Import partner logos and images
import partner2 from "../../assests/Partnership/octa.jpg";
import partners from "../../assests/Partnership/partnership2.jpg";

const Partner = ({ data }) => {
  // Fallback data if API data is unavailable
  const partnerdata = {
    section_title: "Partnerships",
    title: "Industry Leading IDAM Solutions:",
    title_content: `Establishing a Comprehensive Defense Strategy: Empowering and protecting premier organizations with an integrated platform. 
    Simplify access policy management, clearly define group memberships, and seamlessly enforce security protocols.`,
    title_2: "Security and Compliance Solutions",
    title_2_content: "Fortify Your Digital Perimeter...",
    image: "uploads/partnership/fallback_image.jpg", // Fallback image
    logos: [
      "uploads/partnerslogo/1733473465_6752b4b932c0f.jpg",
      "uploads/partnerslogo/1733473471_6752b4bf57532.jpg",
      "uploads/partnerslogo/1733473479_6752b4c780c5b.png",
      "uploads/partnerslogo/1733473485_6752b4cddf4ec.jpg",
    ], // Fallback logos
    downlogos: [
      "uploads/partnersdownlogo/1733473390_6752b46ecb4d2.jpg",
      "uploads/partnersdownlogo/1733473402_6752b47addae5.jpg",
    ], // Fallback downlogos
  };
  // Destructure data with fallback to partnerdata
  
  // Destructure data with fallback to partnerdata
  const {
    section_title,
    title,
    title_content,
    title_2,
    title_2_content,
    image,
    logos,
    downlogos,
  } = data?.Home_partnership || partnerdata;

  // Base API URL or empty fallback
  const apiBaseUrl = process.env.REACT_APP_API || "";

  // Process full image and partner logos
  const fullImage = `${apiBaseUrl}${image}`;
  const partnerLogos = logos || [];
  const partnerDownLogos = downlogos || [];

  console.log("Partner Logos:", partnerLogos);
  console.log("Partner Downlogos:", partnerDownLogos);
  return (
    <section className="cloud_full">
      <Container>
        <Row className="align-items-center">
          {/* Left Column */}
          <Col md={12} sm={12} lg={6}>
            <div className="cloud_cnt">
              <p className="desc">{section_title || "Partnerships"}</p>
              <p className="partner-title">
                <b>{title || "Industry Leading IDAM Solutions:"}</b>
              </p>
              <p className="sub_desc">{title_content || "Establishing a Comprehensive Defense Strategy: Empowering and protecting premier organizations with an integrated platform. Simplify access policy management, clearly define group memberships, and seamlessly enforce security protocols."}</p>
              <div className="total_list">
              
              
                {/* First Row of Partner Logos */}
                <div className="list partner-images d-flex justify-content-center flex-wrap">
                {partnerLogos.length > 0 ? (
          partnerLogos.map((logo, index) => (
            <div key={index} className={`partner-logo partner-${index + 1}`}>
              <img
                src={`${apiBaseUrl}${logo}`}
                alt={`Partner Logo ${index + 1}`}
                className="partner-image"
                onError={(e) => (e.target.src = "/fallback/logo.jpg")}
              />
            </div>
          ))
        ) : (
          <p>No partner logos available.</p>
        )}
      </div>



                <p className="partner-title">
                  <b>{title_2 || "Security and Compliance Solutions"}</b>
                </p>
                <p className="sub_desc">{title_2_content || "Fortify Your Digital Perimeter: Streamline and expedite your compliance efforts with industry-leading solutions. Reduce costs and time with rapid remediation and automated attack path management, ensuring your organization remains secure and compliant."}</p>
                
                
                
                {/* Second Row of Partner Logos */}
                <div className="list partner-images d-flex justify-content-center flex-wrap">
                {partnerDownLogos.length > 0 ? (
          partnerDownLogos.map((logo, index) => (
            <div
              key={index}
              className={`partner-logo partner-${ partnerLogos.length + index + 1|| index + 1}`}
            >
              <img
                src={`${apiBaseUrl}${logo}`}
                alt={`Down Partner Logo ${index + 1}`}
                className="partner-down-image"
                onError={(e) => (e.target.src = "/fallback/down-logo.jpg")}
              />
            </div>
          ))
        ) : (
          <p>No down partner logos available.</p>
        )}
      </div>



              </div>
            </div>
          </Col>
          {/* Right Column */}
          <Col md={12} lg={6}>
            <div className="image partnerimage">
              <img
                src={fullImage }
                alt="Cloud solutions overview"
                className="partner-main-image"
                onError={(e) => (e.target.src = "./Partner.jsx")}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Partner;
