import React from 'react';
import './WhyChooseSecuroak.css';
import securoakImage from './whychoose.jpg';
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos'; // Import AOS
import  { useState ,useEffect } from 'react';
import DOMPurify from 'dompurify';
import { Helmet } from 'react-helmet';


const WhyChooseSecuroak = () => {
  const [itemsToDisplay, setItemsToDisplay] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}api/identify-offerings`);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const result = await response.json();
        console.log("API Response:", result); // Log to verify structure
        setData(result);
      } catch (err) {
        console.error("API Fetch Error:", err.message);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    AOS.init({ duration: 1000, once: true });
  }, []);

  

  return (
    <section className="why-securoak-section" data-aos="fade-up">
      <div className="why-securoak-top">
        <h2 className="why-securoak-title">
      
        {data?.customer_identity_access_management?.why_choose_title || "Why Choose Securoak" } 
          </h2>
        <p className="why-securoak-description">
        
        
        {data?.customer_identity_access_management?.why_choose_content || "  With Securoak, you enhance your digital identity management capabilities and position your organization as a trusted leader in the marketplace. Let’s unlock your potential together." } 
        
        </p>
      </div>

      <div className="why-securoak-content">
        <div className="why-securoak-image">
      
   
          <img src= {data?.customer_identity_access_management?.why_choose_image|| securoakImage} alt="Securoak" className="why-image" />
        </div>

        <div className="why-securoak-features why-securoak-features2">
        <div
       
       dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(
          data?.customer_identity_access_management?.content_editor
            ? data.customer_identity_access_management.content_editor.replace('<ul>', '<ul class="features-list">')
            : ""
        ),
      }}
        />
        </div>
      </div>
    </section>
  );
};

export default WhyChooseSecuroak;
