import React, { useEffect, useState } from 'react';
import './UpcomingEvents.scss';
import event1 from './event1.jpg'; // Import the image file
import event2 from './event2.jpg'; // Import the image file
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Header from '../../header/header';
import Footer from '../../footer/footer';
import banner from "./eventsbanner.jpg";
import { Helmet } from 'react-helmet';
import DOMPurify from 'dompurify';
import * as FaIcons from "react-icons/fa";

const UpcomingEvents = () => {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}api/events`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        setData(result[0]); // Update state with API response
      } catch (error) {
        // setError(error.message); 
      } finally {
        setLoading(false); // Stop loading indicator
      }
    };

    fetchData();
  }, []);


 // Map events data or set default events if no data
 const eventsdata = data?.events?.length > 0
 ? data.events.map((item) => ({
     id: item.id,
     image: item.event_image || event1, // Use the full URL directly from the API or fallback to default
     title: item.title || "update Soon", // Fallback title
     date: item.date || "Update Soon", // Fallback date
     venue: item.venue || "update Soon", // Fallback venue
     booth: item.booth || "update Soon", // Fallback booth
     content: item.content || "update Soon.", // Fallback content
   }))
 : []; // Return an empty array if events data is not available or is empty



  return (
    <>
    <Helmet>
  <title>{data?.meta_title || "Upcoming Events"}</title>
  <meta
    name="description"
    content={data?.meta_description || "Upcoming Events"}
  />
  <meta
    name="keywords"
    content={
      data?.meta_keywords ||
      "Upcoming Events"
    }
  />
</Helmet>
    <section className="upcoming-events" id="upcoming-events">
      <Header identityPage={true} />

      {/* Banner Section */}
      <div className="identity-image-container">
        <img
          src={data?.banner_image || banner}
          alt="Event Banner"
          className="identity-page-image"
        />
        <div className="overlay-text">
          <h1> {data?.title1 || "Our Upcoming Events"}</h1>
          <p>
            Home <i className="fa-solid fa-greater-than banner-icon"></i> {data?.title1 || "Upcoming Events"}
          </p>
        </div>
      </div>

      {/* Event Details */}
      <Container className="event-container">
      
      <Row className="event-row">
        {eventsdata.map((event, index) => (
          <Col md={6} className="event-col" key={event.id}>
            <div className="event-card">
              <Row>
                <Col md={12}lg={6} className="event-img-col">
                  <img
                    src={event.image}
                    alt={event.title}
                    className="event-img"
                    onError={(e) => {
                      e.target.src = "/default-image.png";
                    }}
                  />
                </Col>
                <Col md={12} lg={6} className="event-text-col">
                  <h3 className="event-title">{event.title}</h3>
                  <p className="date">
                    <strong><i className="fa-solid fa-calendar"></i> Date:</strong> {event.date}
                  </p>
                  <p className="venue">
                    <strong><i className="fa-solid fa-map-marker-alt"></i> Venue:</strong> {event.venue}
                  </p>
                  <p className="booth">
                    <strong><i className="fa-solid fa-handshake"></i> Meet us at:</strong> {event.booth}
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
      
      <Footer />
    </section>

  </>
  );
};

export default UpcomingEvents;
